import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import JoditEditor from "jodit-react";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { Modal, Form, Table, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./Manageepisode.css";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import cancel_video from "../../../components/Images/icons/cancel_video.svg";
import retry_video from "../../../components/Images/icons/retry_video.svg";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { SliderFormatterComponent, statusTranscodeFormatter, } from "../../../components/CommonMethod/CommonBoostrapFormatter";
import useCompressImageConfig from "../../../components/CommonApis/Compressimageapi";
import usePpvGlobalImageConfig from "../../../components/CommonApis/ppv_global_change";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CiShare1 } from "react-icons/ci";
import NavigationData from "../../../layout/Sidebar/NavigationData";

export default function Manageepisode(props) {
  const [itemToDeleteEpisodeepisode, setItemToDeleteEpisodeepisode] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [menuItems, setMenuItems] = useState([]);

  const handleNextNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const { label_management } = NavigationData();
  const [datanewppvpprice, setDatanewppvpprice] = useState();
  const [series, setSeries] = useState([]);
  const [count, setCount] = useState();
  const [data, setData] = useState([]);
  const [videodata, setVideodata] = useState("");
  const [defaultImage, setDefaultimage] = useState();
  const [defaultImageurl, setDefaultimageurl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [message, setMessage] = useState("");

  const [Subtitlesmap, setSubtitlesmap] = useState([]);
  const { id } = useParams();
  const { seriesid } = useParams();

  const [episode_id, setEpisode_id] = useState("");

  const [type, setType] = useState("");
  const [ppv_status, setPpv_status] = useState("");
  const [description, setDescription] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [url, setUrl] = useState("");
  const [path, setPath] = useState("");
  const [disk, setDisk] = useState("");
  const [stream_path, setStream_path] = useState("");
  const [processed_low, setProcessed_low] = useState("");
  const [converted_for_streaming_at, setConverted_for_streaming_at] =
    useState("");
  const [active, setActive] = useState("");
  const [featured, setFeatured] = useState("");

  const [free_duration_status, setFree_duration_status] = useState("");
  const [banner, setBanner] = useState("");
  const [footer, setFooter] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [views, setViews] = useState("");
  const [rating, setRating] = useState("");
  const [status, setStatus] = useState("");
  const [free_content_duration, setFree_content_duration] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [episode_ads, setEpisode_ads] = useState("");
  const [ads_position, setAds_position] = useState("");
  const [websitetitle, setWebsitetitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [websiteurl, setWebsiteurl] = useState("");

  const [editUser, setEditUser] = useState({
    title: "",
    duration: "",
    slug: "",
    free_duration_time: "",
    free_duration_status: "",
    active: "1",
    year: "",
    ppv_price: "",
    source_type: "",
    PPVType: "",
    global_ppv: "",
    ios_ppv_price: "",
    ads_category: "",
    mid_advertisement_sequence_time: "",

  });
  // console.log(editUser)
  const [videosubtitles, setVideoSubtitles] = useState({});

  const handleDropsubtitles = (language, acceptedFiles) => {
    // Handle dropped files here
    setVideoSubtitles((prevState) => ({
      ...prevState,
      [language]: acceptedFiles,
    }));
  };

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [user_access, setUser_access] = useState([]);
  const [ads_category, setAds_category] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [age_restrict, setAge_restrict] = useState([]);
  const [publish_time, setPublish_time] = useState("");

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const [ios_ppv_price, setIos_ppv_price] = useState("");

  // console.log(userAccessesValue, "userAccess");

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };
  const handleAutoFillChangetitle = (event) => {
    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };
  const [inputValueyear, setInputValueyear] = useState("");

  const handleChangeyear = (event) => {
    const value = event.target.value;
    setEditUser({ ...editUser, year: value });
  };

  const [userTime, setUserTime] = useState({
    skip_intro: "",
    intro_start_time: "",
    intro_end_time: "",
    skip_recap: "",
    recap_start_time: "",
    recap_end_time: "",
    url_linktym: "",
  });

  const insertColons = (time) => {
    if (time?.length >= 6) {
      const hours = time?.substr(0, 2);
      const minutes = time?.substr(2, 2);
      const seconds = time?.substr(4, 2);

      return `${hours}:${minutes}:${seconds}`;
    }
    return time;
  };

  const [errors, setErrors] = useState({
    error_skip_start_time: "",
    error_skip_end_time: "",
    error_skip_start_session: "",
    error_recap_start_time: "",
    error_recap_end_time: "",
    error_recap_start_session: "",
  });

  async function saveShuffleOrder(updatedItems) {
    if (!updatedItems || updatedItems.length === 0) {
      console.error("Menu items are empty");
      return;
    }

    const ids = updatedItems.map((menuItem) => menuItem.id);
    const positions = updatedItems.map((_, index) => index + 1);

    const formData = new FormData();
    formData.append('id', JSON.stringify(ids));
    formData.append('position', JSON.stringify(positions));
    formData.append('season_id', updatedItems.map(menuItem => menuItem.season_id).slice(0, 1)[0].toString());

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/episode/order`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;
        resultapi = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        console.log(resultError);
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(filteredEpisodeepisodeData);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setMenuItems(reorderedItems);
    saveShuffleOrder(reorderedItems);
  };

  const handleInputintrotime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setUserTime({
      ...userTime,
      [inputName]: formattedTime,
    });

    validateInput(inputName, formattedTime);
  };

  const validateInput = (inputName, formattedTime) => {
    const errorsCopy = { ...errors };

    switch (inputName) {
      case "skip_start_time":
        if (formattedTime === "") {
          errorsCopy.error_skip_start_time = "";
        } else {
          errorsCopy.error_skip_start_time = "";
        }
        break;

      case "skip_end_time":
        if (formattedTime === "") {
          errorsCopy.error_skip_end_time = "";
        } else if (formattedTime <= userTime.skip_start_time) {
          errorsCopy.error_skip_end_time =
            "Skip End time must be greater than Skip Start time";
        } else {
          errorsCopy.error_skip_end_time = "";
        }
        break;

      case "skip_start_session":
        if (formattedTime === "") {
          errorsCopy.error_skip_start_session = "";
        } else if (formattedTime <= userTime.skip_end_time) {
          errorsCopy.error_skip_start_session =
            "Skip Start Session must be greater than Skip End time";
        } else {
          errorsCopy.error_skip_start_session = "";
        }
        break;

      case "recap_start_time":
        if (formattedTime === "") {
          errorsCopy.error_recap_start_time = "";
        } else {
          errorsCopy.error_recap_start_time = "";
        }
        break;

      case "recap_end_time":
        if (formattedTime === "") {
          errorsCopy.error_recap_end_time = "";
        } else if (formattedTime <= userTime.recap_start_time) {
          errorsCopy.error_recap_end_time =
            "Recap End time must be greater than Recap Start time";
        } else {
          errorsCopy.error_recap_end_time = "";
        }
        break;

      case "recap_start_session":
        if (formattedTime === "") {
          errorsCopy.error_recap_start_session = "";
        } else if (formattedTime <= userTime.recap_end_time) {
          errorsCopy.error_recap_start_session =
            "Recap Start Session must be greater than Recap End time";
        } else {
          errorsCopy.error_recap_start_session = "";
        }
        break;

      default:
        break;
    }

    setErrors(errorsCopy);
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    setActive({ [e.target.name]: e.target.value });
    setFeatured({ ...featured, [e.target.name]: e.target.value });
    setStatus({ ...status, [e.target.name]: e.target.value });
    setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });
    setBanner({ ...banner, [e.target.name]: e.target.value });
    setActive({ ...active, [e.target.name]: e.target.value });
    setViews({ ...views, [e.target.name]: e.target.value });
    setFooter({ ...footer, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setPpv_status({ ...ppv_status, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setStatus({ ...status, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setViews({ ...views, [e.target.name]: 1 });
      setFooter({ ...footer, [e.target.name]: 1 });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 1,
      });
    } else {
      setPpv_status({ ...ppv_status, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setViews({ ...views, [e.target.name]: 0 });
      setFooter({ ...footer, [e.target.name]: 0 });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 0,
      });
    }
  };

  const contentFieldChanaged = (data) => {
    setDescription(data);
    // console.log("datasssssss", data);
  };

  const handleTagsChange = (newTags) => {
    // Convert tags to lowercase, remove duplicates, and filter out empty tags
    const cleanedTags = newTags
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag !== "")
      .filter((value, index, self) => self.indexOf(value) === index);
    setSearchTags(cleanedTags);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  // const cancelUpload = () => {
  //   if (cancelFileUpload.current)
  //     cancelFileUpload.current("User has canceled the file upload.");
  // };

  const [uploadPercentagefile, setUploadPercentagefile] = useState(0);
  const cancelFileUploadfile = useRef(null);

  const cancelUploadfile = () => {
    if (cancelFileUploadfile.current)
      cancelFileUploadfile.current("User has canceled the file upload.");
  };

  const [uploadProgress, setUploadProgress] = useState(0);
  // const [uploadCompleted, setUploadCompleted] = useState(false);
  // const [uploadedFiles, setUploadedFiles] = useState([]);



  // const handleUpload = async (files) => {
  //   try {
  //     setLoading(true); // Set loading to true when starting the upload

  //     // API endpoint for video upload
  //     const uploadUrl = `${process.env.REACT_APP_Baseurl}/admin/Episode_Upload`;

  //     // Headers for the request
  //     const headers = {
  //       Authorization: 'Bearer ' + access_token, // You need to define access_token
  //       'Access-Control-Allow-Origin': '*',
  //     };

  //     const uploadPromises = files.map(async (file) => {
  //       const formData = new FormData();
  //       formData.append("file", file);
  //       formData.append("series_id", seriesid);
  //       formData.append("season_id", id);

  //       try {
  //         const response = await axios.post(uploadUrl, formData, {
  //           headers: {
  //             ...headers,
  //             'Content-Type': 'multipart/form-data',
  //           },
  //           onUploadProgress: (progressEvent) => {
  //             const percentCompleted = Math.round(
  //               (progressEvent?.loaded * 100) / progressEvent?.total
  //             );
  //             updateProgress(file.name, percentCompleted);
  //           },
  //         });

  //         // Create details about uploaded file
  //         const uploadedDetail = {
  //           name: file.name,
  //           size: (file.size / (1024 * 1024)).toFixed(2),
  //         };

  //         setUploadedFiles((prevUploadedFiles) => [
  //           ...prevUploadedFiles,
  //           uploadedDetail,
  //         ]);

  //         props?.setApiresponsealert(response.data);
  //         props?.setShowalert(true);

  //         if (response?.data.status === true) {
  //           console.log(response?.data)
  //           setEditUser(response?.data?.Episode);
  //           setEpisode_id(response?.data?.Episode_id);
  //         } else {
  //           // Handle error response
  //           props?.setApiresponsealert(response.data);
  //           props?.setShowalert(true);
  //           updateProgress(file.name, 0);
  //         }

  //       } catch (error) {
  //         // Handle error case
  //         console.error('Error uploading video:', error);
  //         setLoading(false); // Set loading to false in case of error
  //       }
  //     });

  //     await Promise.all(uploadPromises);

  //     setUploadCompleted(true);
  //     props?.setApiresponsealert({
  //       status: true,
  //       message: 'All videos uploaded successfully',
  //     });
  //     props?.setShowalert(true);
  //     setLoading(false); // Set loading to false when upload completes
  //   } catch (error) {
  //     // Handle general error case
  //     console.error('Error uploading videos:', error);
  //     setLoading(false); // Set loading to false in case of error
  //   }
  // };


  // { EPISODE LIST data}

  const [episodeepisodeData, setEpisodeepisodeData] = useState([]);
  const [filteredEpisodeepisodeData, setFilteredEpisodeepisodeData] = useState([]);
  const [selectAllEpisodeepisode, setSelectAllEpisodeepisode] = useState(false);
  const [filterEpisodeepisode, setFilterEpisodeepisode] = useState("");
  const [modalCloseEpisodeepisode, setModalCloseEpisodeepisode] = useState(false);
  const [seriesParent, setSeriesParent] = useState({})

  const fetchApiepisodeepisode = async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/series_season/edit/${seriesid}/${id}`, {
        headers: headers,
      })
      .then((response) => {
        const episodeepisodeList = response?.data?.episodes;
        const updatedEpisodeepisodeList = episodeepisodeList?.map((item) => {
          const uploadBy =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1
              ? "PUBLISHED"
              : item?.active === 0 || item?.active === null
                ? "UNPUBLISHED"
                : "DRAFT";
          const statusTranscodeText = item?.draft == 0 ? "In Process" : "Done"

          return { ...item, uploaded_by: uploadBy, active: statusText, draft: statusTranscodeText };
        });
        setEpisodeepisodeData(updatedEpisodeepisodeList);
        setSeriesParent(response?.data?.series)
        setFilteredEpisodeepisodeData(updatedEpisodeepisodeList);
        setLoading(false);
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  useEffect(async () => {
    fetchApiepisodeepisode();
  }, []);

  const commonPaginationConfigepisode = (sizePerPageList) => {
    return paginationFactory({
      page: 1,
      sizePerPage: 10,
      nextPageText: "Next",
      prePageText: "Prev",
      firstPageText: <MdKeyboardDoubleArrowLeft />,
      lastPageText: <MdKeyboardDoubleArrowRight />,
      showTotal: true,
      alwaysShowAllBtns: true,
      sizePerPageList: sizePerPageList,
    });
  };
  const episodeepisodeSizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredEpisodeepisodeData?.length },
  ];


  /////Episode start
  const selectRowEpisodeepisode = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteEpisodeepisode([...itemToDeleteEpisodeepisode, row?.id]);
        } else {
          setItemToDeleteEpisodeepisode(
            itemToDeleteEpisodeepisode.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteEpisodeepisode,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAllEpisodeepisode}
        />
      </div>
    ),
  };

  const multiSelect = (e, type) => {
    const { value, checked } = e.target;

    if (type === "all") {
      if (checked) {
        const ids = filteredEpisodeepisodeData?.map((data) => data?.id) || [];
        setItemToDeleteEpisodeepisode(ids);
      } else {
        setItemToDeleteEpisodeepisode([]);
      }
    } else {
      setItemToDeleteEpisodeepisode((prev) => {
        const valueAsString = Number(value); // Ensure value is treated as a string
        if (checked) {
          // Add item if it is checked and not already present
          return prev.includes(valueAsString) ? prev : [...prev, valueAsString];
        } else {
          // Remove item if it is unchecked
          return prev.filter((item) => item !== valueAsString);
        }
      });

    }
  };

  useEffect(() => {
  }, [itemToDeleteEpisodeepisode]);


  const episodeepisodeFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <img
          src={row?.Thumbnail ? row?.Thumbnail : defaultImage}
          className="profile"
          alt="image_all"
        />

        <div className="rightVideoTitle">
          <p>{data}</p>
          <span className="me-1 text-dark dateText"> {row?.duration}</span>
          <span className="dateText">{row?.upload_on}</span>

          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/episode/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const statusFormatterEpisodeepisode = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "DRAFT"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };

  const actionFormatterEpisodeepisode = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit-episode/manage/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2"> Edit Episode </span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteEpisodeepisode(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2"> Delete Episode</span>
          </div>
        </div>
      </div>
    );
  };
  const columnsEpisodeepisode = [
    {
      dataField: "title",
      text: "Episodes",
      formatter: episodeepisodeFormatter,
      sort: true,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded by",
      sort: true,
    },

    {
      dataField: "active",
      text: "Status",
      formatter: statusFormatterEpisodeepisode,
    },
    {
      dataField: "banner",
      text: "Slider",
      formatter: (cell, row) => <SliderFormatterComponent cell={cell} row={row} action={handleToggle} />,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatterEpisodeepisode,
    },
  ];
  const exportHeadersEpisodeepisode = [
    { label: "ID", key: "id" },
    { label: "EPISODE", key: "title" },
    { label: "DURATION", key: "duration" },
    { label: "STATUS", key: "active" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD-ON", key: "upload_on" },
  ];
  const conditionalGeneratePDFEpisodeepisode = (get) => {
    const doc = new jsPDF();
    doc.text("ALL EPISODE LIST ", 10, 10);
    const columns = [
      "INDEX",
      "ID",
      "EPISODE NAME",
      "DURATION",
      "STATUS",
      "UPLOADED BY",
      "UPLOAD-ON",
    ];
    const allDataPDF = episodeepisodeData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.uploaded_by,
      item?.upload_on,
    ]);
    const filterPDF = filteredEpisodeepisodeData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.uploaded_by,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save(get === 1 ? "ALL-EPISODE-LIST.pdf" : "FILTERED-EPISODE-LIST");
  };

  const generateXLSXEPISODEepisode = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(
        get === 1 ? episodeepisodeData : filteredEpisodeepisodeData
      );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(
      wb,
      get === 1 ? "ALL-EPISODE-LIST.xlsx" : "FILTERED-EPISODE-LIST.xlsx"
    );
  };

  const handleFilterChangeEpisodeepisode = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilterEpisodeepisode(searchTerm);
    const filteredResults = episodeepisodeData?.filter((item) =>
      ["title", "duration", "active", "uploaded_by", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredEpisodeepisodeData(filteredResults);
  };

  const closeEpisodeepisodeModal = () => {
    setModalCloseEpisodeepisode(!modalCloseEpisodeepisode);
  };

  const openModalSingleDeleteEpisodeepisode = (id) => {
    setItemToDeleteEpisodeepisode([id]);
    setModalCloseEpisodeepisode(true);
  };

  const openModalMultiDeleteEpisodeepisode = () => {
    setModalCloseEpisodeepisode(true);
  };

  const handleDeleteEpisodeepisodeData = () => {
    closeEpisodeepisodeModal();
    episodeepisodeDeleteOperation();
  };

  const episodeepisodeDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteEpisodeepisode + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/episode/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };
  const handleClearEpisodeepisode = () => {
    fetchApiepisodeepisode();
    setFilterEpisodeepisode("");
  };
  const handleAllEpisodeepisode = () => {
    setSelectAllEpisodeepisode(!selectAllEpisodeepisode);
    setItemToDeleteEpisodeepisode(
      selectAllEpisodeepisode ? [] : filteredEpisodeepisodeData?.map((item) => item?.id)
    );
  };


  // {  EPISODE LIST data}



  // { Master Library data}
  // { Master Library data}


  // const [loadings, setLoadings] = useState(false);
  // const [uploadedFiles, setUploadedFiles] = useState([]);
  // const [uploadCompleted, setUploadCompleted] = useState(false);
  // const [progress, setProgress] = useState({});
  // const [cancelTokens, setCancelTokens] = useState({});
  // const [pausedFiles, setPausedFiles] = useState({});
  // const [failedFiles, setFailedFiles] = useState([]);

  // const maxRetries = 3;

  // const updateProgress = (fileName, percentCompleted) => {
  //   setProgress(prevProgress => ({
  //     ...prevProgress,
  //     [fileName]: percentCompleted,
  //   }));
  // };

  // const overallProgress = () => {
  //   const totalFiles = Object.keys(progress).length;
  //   const totalProgress = Object.values(progress).reduce((acc, value) => acc + value, 0);
  //   return totalFiles ? totalProgress / totalFiles : 0;
  // };

  // const uploadFileWithRetry = async (file, retryCount = 0, start = 0) => {
  //   const formData = new FormData();
  //   formData.append('file', file.slice(start), file.name);
  //   formData.append("series_id", seriesid);
  //   formData.append("season_id", id);
  //   // formData.append("UploadlibraryID", document.getElementById('UploadlibraryID').value); // Adjust this as needed
  //   // formData.append("_token", CSRF_TOKEN);

  //   const cancelTokenSource = axios.CancelToken.source();
  //   setCancelTokens(prevTokens => ({ ...prevTokens, [file.name]: cancelTokenSource }));

  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/Episode_Upload`, formData, {
  //       headers: {
  //         Authorization: 'Bearer ' + access_token,
  //         'Content-Type': 'multipart/form-data',
  //         'Access-Control-Allow-Origin': '*',
  //         'Content-Range': `bytes ${start}-${file.size - 1}/${file.size}`
  //       },
  //       onUploadProgress: (progressEvent) => {
  //         const percentCompleted = Math.round(((progressEvent.loaded + start) * 100) / file.size);
  //         updateProgress(file.name, percentCompleted);
  //       },
  //       cancelToken: cancelTokenSource.token
  //     });

  //     const uploadedDetail = {
  //       name: file.name,
  //       size: (file.size / (1024 * 1024)).toFixed(2),
  //     };

  //     setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, uploadedDetail]);

  //     props?.setApiresponsealert(response.data);
  //     props?.setShowalert(true);

  //     if (response.data.status === true) {
  //       setEditUser(response?.data?.Episode);
  //       setEpisode_id(response?.data?.Episode_id);
  //       props?.setApiresponsealert({ status: true, message: `Successfully uploaded ${file.name}` });
  //     } else {
  //       props?.setApiresponsealert(response.data);
  //       props?.setShowalert(true);
  //       updateProgress(file.name, 0);
  //     }

  //   } catch (error) {
  //     if (axios.isCancel(error)) {
  //       console.log('Upload paused:', file.name);
  //       setPausedFiles(prevPausedFiles => ({ ...prevPausedFiles, [file.name]: start }));
  //     } else {
  //       console.error('Error uploading video:', error);
  //       if (retryCount < maxRetries) {
  //         console.log(`Retrying upload for ${file.name} (Attempt ${retryCount + 1})`);
  //         await uploadFileWithRetry(file, retryCount + 1, start);
  //       } else {
  //         props?.setApiresponsealert({ status: false, message: `Failed to upload ${file.name} after ${maxRetries} attempts.` });
  //         props?.setShowalert(true);
  //         setFailedFiles(prevFailedFiles => [...prevFailedFiles, file]);
  //       }
  //     }
  //   }
  // };

  // const handleUpload = async (files) => {
  //   try {
  //     setLoadings(true);

  //     const uploadPromises = files.map((file) => {
  //       const start = pausedFiles[file.name] || 0;
  //       return uploadFileWithRetry(file, 0, start);
  //     });

  //     await Promise.all(uploadPromises);

  //     setLoadings(false);
  //     setUploadCompleted(true);
  //   } catch (error) {
  //     console.error('Error uploading videos:', error);
  //     setLoadings(false);
  //   }
  // };

  // const cancelUpload = (fileName) => {
  //   if (cancelTokens[fileName]) {
  //     cancelTokens[fileName].cancel(`Upload canceled for ${fileName}`);
  //     setCancelTokens(prevTokens => {
  //       const updatedTokens = { ...prevTokens };
  //       delete updatedTokens[fileName];
  //       return updatedTokens;
  //     });
  //   }
  // };

  // const pauseUpload = (fileName) => {
  //   if (cancelTokens[fileName]) {
  //     cancelTokens[fileName].cancel(`Upload paused at ${progress[fileName]}`);
  //     setCancelTokens(prevTokens => {
  //       const updatedTokens = { ...prevTokens };
  //       delete updatedTokens[fileName];
  //       return updatedTokens;
  //     });
  //   }
  // };

  // const resumeUpload = (file) => {
  //   if (pausedFiles[file.name] !== undefined) {
  //     const start = pausedFiles[file.name];
  //     const fileToResume = new File([file], file.name, { type: file.type });
  //     uploadFileWithRetry(fileToResume, 0, start);
  //     setPausedFiles(prevPausedFiles => {
  //       const updatedPausedFiles = { ...prevPausedFiles };
  //       delete updatedPausedFiles[file.name];
  //       return updatedPausedFiles;
  //     });
  //   }
  // };

  // const retryUpload = (file) => {
  //   setFailedFiles(prevFailedFiles => prevFailedFiles.filter(f => f.name !== file.name));
  //   handleUpload([file]);
  // };





  // VIDEO UPLOAD UPLOAD 
  // VIDEO UPLOAD UPLOAD 

  const [loadings, setLoadings] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [progress, setProgress] = useState({});
  const [cancelTokens, setCancelTokens] = useState({});
  const [pausedFiles, setPausedFiles] = useState({});
  const [failedFiles, setFailedFiles] = useState([]);

  const maxRetries = 3;

  const updateProgress = (fileName, percentCompleted) => {
    setProgress(prevProgress => ({
      ...prevProgress,
      [fileName]: percentCompleted,
    }));
  };

  const overallProgress = () => {
    const totalFiles = Object.keys(progress).length;
    const totalProgress = Object.values(progress).reduce((acc, value) => acc + value, 0);
    return totalFiles ? totalProgress / totalFiles : 0;
  };
  const [uploadSpeed, setUploadSpeed] = useState({});
  const [fileSizes, setFileSizes] = useState({});


  const uploadFileWithRetry = async (file, retryCount = 0, start = 0) => {
    const formData = new FormData();
    formData.append('file', file.slice(start), file.name);
    formData.append("series_id", seriesid);
    formData.append("season_id", id);

    const cancelTokenSource = axios.CancelToken.source();
    setCancelTokens(prevTokens => ({ ...prevTokens, [file.name]: cancelTokenSource }));

    const uploadStartTime = Date.now();
    let previousUploaded = start;

    try {
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/Episode_Upload`, formData, {
        headers: {
          Authorization: 'Bearer ' + access_token,
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Content-Range': `bytes ${start}-${file.size - 1}/${file.size}`
        },
        onUploadProgress: (progressEvent) => {
          const currentTime = Date.now();
          const timeElapsed = (currentTime - uploadStartTime) / 1000; // Time in seconds

          // Calculate percent completed
          const percentCompleted = Math.round(((progressEvent.loaded + start) * 100) / file.size);
          updateProgress(file.name, percentCompleted);

          // Calculate upload speed
          const uploadedSize = progressEvent.loaded + start - previousUploaded;
          let speed = uploadedSize / timeElapsed; // Bytes per second

          let formattedSpeed;
          if (speed > 1024 * 1024) {
            // MB/sec
            formattedSpeed = `${(speed / (1024 * 1024)).toFixed(2)} MB/sec`;
          } else if (speed > 1024) {
            // KB/sec
            formattedSpeed = `${(speed / 1024).toFixed(2)} KB/sec`;
          } else {
            // Bytes/sec
            formattedSpeed = `${speed.toFixed(2)} B/sec`;
          }

          // Update speed in state for UI display
          setUploadSpeed(prevSpeeds => ({ ...prevSpeeds, [file.name]: formattedSpeed }));
          previousUploaded = progressEvent.loaded + start;
        },
        cancelToken: cancelTokenSource.token
      });

      // Handling successful upload response
      const uploadedDetail = {
        name: file.name,
        size: (file.size / (1024 * 1024)).toFixed(2),
      };

      setUploadedFiles(prevUploadedFiles => [...prevUploadedFiles, uploadedDetail]);
      props?.setApiresponsealert(response.data);
      props?.setShowalert(true);

      if (response.data.status === true) {
        setEditUser(response?.data?.Episode);
        setEpisode_id(response?.data?.Episode_id);
        props?.setApiresponsealert({ status: true, message: `Successfully uploaded ${file.name}` });
      } else {
        props?.setApiresponsealert(response.data);
        props?.setShowalert(true);
        updateProgress(file.name, 0);
      }
    } catch (error) {
      // Error handling with retry logic
      if (axios.isCancel(error)) {
        console.log('Upload paused:', file.name);
        setPausedFiles(prevPausedFiles => ({ ...prevPausedFiles, [file.name]: start }));
      } else {
        console.error('Error uploading video:', error);
        if (retryCount < maxRetries) {
          console.log(`Retrying upload for ${file.name} (Attempt ${retryCount + 1})`);
          await uploadFileWithRetry(file, retryCount + 1, start);
        } else {
          props?.setApiresponsealert({ status: false, message: `Failed to upload ${file.name} after ${maxRetries} attempts.` });
          props?.setShowalert(true);
          setFailedFiles(prevFailedFiles => [...prevFailedFiles, file]);
        }
      }
    }
  };

  const handleUpload = async (files) => {
    try {
      setLoading(true);

      const fileSizes = {}; // Initialize an object to store file sizes

      const uploadPromises = files.map((file) => {
        const start = pausedFiles[file.name] || 0;
        fileSizes[file.name] = (file.size / (1024 * 1024)).toFixed(2); // Calculate file size in MB
        return uploadFileWithRetry(file, 0, start);
      });

      setFileSizes(fileSizes); // Update state with file sizes

      await Promise.all(uploadPromises);

      setLoading(false);
      setUploadCompleted(true);
    } catch (error) {
      console.error('Error uploading videos:', error);
      setLoading(false);
    }
  };


  const cancelUpload = (fileName) => {
    if (cancelTokens[fileName]) {
      cancelTokens[fileName].cancel(`Upload canceled for ${fileName}`);
      setCancelTokens(prevTokens => {
        const updatedTokens = { ...prevTokens };
        delete updatedTokens[fileName];
        return updatedTokens;
      });
    }
  };

  const pauseUpload = (fileName) => {
    if (cancelTokens[fileName]) {
      cancelTokens[fileName].cancel(`Upload paused at ${progress[fileName]}`);
      setCancelTokens(prevTokens => {
        const updatedTokens = { ...prevTokens };
        delete updatedTokens[fileName];
        return updatedTokens;
      });
    }
  };

  const resumeUpload = (file) => {
    if (pausedFiles[file.name] !== undefined) {
      const start = pausedFiles[file.name];
      const fileToResume = new File([file], file.name, { type: file.type });
      uploadFileWithRetry(fileToResume, 0, start);
      setPausedFiles(prevPausedFiles => {
        const updatedPausedFiles = { ...prevPausedFiles };
        delete updatedPausedFiles[file.name];
        return updatedPausedFiles;
      });
    }
  };

  const retryUpload = (file) => {
    setFailedFiles(prevFailedFiles => prevFailedFiles.filter(f => f.name !== file.name));
    handleUpload([file]);
  };


  // VIDEO UPLOAD UPLOAD 
  // VIDEO UPLOAD UPLOAD 




  // const updateProgress = (fileName, progress) => {
  //   setUploadProgress((prevProgress) => ({
  //     ...prevProgress,
  //     [fileName]: progress,
  //   }));
  // };

  // const overallProgress = () => {
  //   const progressValues = Object.values(uploadProgress);
  //   const overallProgress =
  //     progressValues.reduce((acc, val) => acc + val, 0) / progressValues.length;
  //   return isNaN(overallProgress) ? 0 : overallProgress;
  // };

  // const handleUpload = async (files) => {
  //   const uploadUrl = `${process.env.REACT_APP_Baseurl}/admin/Episode_Upload`;
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };

  //   const uploaders = files?.map((file) => {
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     formData.append("series_id", seriesid);
  //     formData.append("season_id", id);

  //     return axios.post(uploadUrl, formData, {
  //       headers: headers,
  //       onUploadProgress: (progressEvent) => {
  //         const percentCompleted = Math.round(
  //           (progressEvent.loaded * 100) / progressEvent.total
  //         );
  //         setUploadProgress(percentCompleted);
  //       },
  //     });
  //   });

  //   try {
  //     await Promise.all(uploaders);
  //     const responses = await Promise.all(uploaders);
  //     // console.log("Responses after upload:", responses);

  //     if (responses[0]?.data?.status === true) {
  //       responses.forEach((response, index) => {
  //         // console.log(`Response for video ${index + 1}:`, response.data.Videos);
  //         setEditUser(response?.data?.Episode);
  //         setEpisode_id(response?.data?.Episode_id);
  //         // Process the response data as needed for each video
  //       });
  //       const uploadedDetails = files?.map((file, index) => ({
  //         name: file?.name,
  //         size: (file?.size / (1024 * 1024)).toFixed(2), // Convert bytes to MB with 2 decimal places
  //       }));

  //       setUploadedFiles(uploadedDetails);
  //       setUploadCompleted(true);
  //       setUploadProgress(0);
  //       props?.setApiresponsealert(responses[0]?.data);
  //       props?.setShowalert(true);
  //     } else {
  //       // Handle error response
  //       var resultError = responses[0]?.data;
  //       props?.setApiresponsealert(resultError);
  //       props?.setShowalert(true);
  //       setUploadProgress(0);
  //     }
  //   } catch (error) {
  //     alert("Error uploading videos. Please try again.");
  //     setUploadProgress(0);
  //   }
  // };

  // const resetUploadState = () => {
  //   setUploadProgress(0);
  //   setUploadCompleted(false);
  //   setUploadedFiles([]);
  // };

  const { compressResolutionFormat } = useCompressImageConfig();

  const [error, setError] = useState({
    file1: '',
    file2: '',
    file3: '',
  });

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);

  // const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const validateImageRatio = (file, expectedRatio, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const ratio = width / height;
        const expectedRatioPrecision = 0.01; // Allow for some minor floating-point precision issues
        const ratioMatch = Math.abs(ratio - expectedRatio) < expectedRatioPrecision;
        const dimensionMatch = (width === expectedWidth && height === expectedHeight);
        if (ratioMatch || dimensionMatch) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error('Error loading image'));
      };
    });
  };


  const handleFileChange = async (event, fileInputRef, setSelectedFile, errorKey) => {
    const file = event.target.files[0];
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));

    if (compressResolutionFormat?.episode === 1) {
      let isValid = false;

      if (fileInputRef === fileInputRef1) {
        isValid = await validateImageRatio(file, 9 / 16, 1080, 1920);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file1: 'Image must be in 9:16 Ratio or 1080x1920px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef2) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file2: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef3) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file3: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      }
    }

    setSelectedFile(file);
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));
  };

  const handleDelete = (index) => {
    setError('');
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      case 3:
        setSelectedFile3(null);
        if (fileInputRef3.current) {
          fileInputRef3.current.value = null;
        }
        break;
      default:
        break;
    }
  };


  const [series_user, setSeries_user] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [ppv_gobal_price, setppv_gobal_price] = useState();
  const [allCurrencyglobal, setAllCurrencyglobal] = useState([]);
  const [allCurrencycustoms, setAllCurrencycustoms] = useState([]);
  const [allCurrencylistfromappichoosed, setAllCurrencylistfromappichoosed] = useState();
  const { allCurrencylistfromappi, globalPpvStatusActive } = usePpvGlobalImageConfig();
  const global_ppv_status_active = globalPpvStatusActive


  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [available_country, setAvailable_country] = useState([]);
  const [
    selectedOptionsAvailable_country,
    setSelectedOptionsAvailable_country,
  ] = useState([]);

  const [related_videos, setRelated_videos] = useState([]);
  const [selectedOptionsRelated_videos, setSelectedOptionsRelated_videos] =
    useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  useEffect(async () => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/episode/create`,
        { headers: headers }
      );
      setBlock_Country(response?.data?.Block_Country);
      setAvailable_country(response?.data?.Available_country);
      setRelated_videos(response?.data?.Related_videos);
      setLanguage(response?.data?.Series_language);
      // setAge_Restrict(response.data.Age_Restrict);
      setArtists(response?.data?.Series_artist);
      setCategory(response?.data?.series_categories);
      setInputValueAPI(response?.data?.ppv_gobal_price);
      // setAudio_albums(response.data.audio_albums);
      // setAlbum(response?.data?.audio_albums);
      const subtitlesmap = response?.data?.Subtitle;
      setSubtitlesmap(subtitlesmap);
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);

      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      var ds_category = response?.data?.ads_category;
      setAds_category(ds_category);
      setInappPurchase(InappPurchase);

      var Defaultppv_gobal_price = response?.data?.ppv_gobal_price;
      setppv_gobal_price(Defaultppv_gobal_price)
      var DefaultCurrencytest = response?.data?.DefaultCurrency[0];
      setAllCurrencylistfromappichoosed(DefaultCurrencytest);
      var DefaultGlobalContentPriceRates = response?.data?.GlobalContentPriceRates;
      setAllCurrencyglobal(DefaultGlobalContentPriceRates);
      var DefaultContentPriceRates = response?.data?.ContentPriceRates;
      setAllCurrencycustoms(DefaultContentPriceRates);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeRelated_videos = (selectedValues) => {
    setSelectedOptionsRelated_videos(selectedValues);
  };

  const handleSelectChangeAvailable_country = (selectedValues) => {
    setSelectedOptionsAvailable_country(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setSelectedOptionscategory(selectedValues);
  };

  // Multiselect

  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/series_season/edit/${seriesid}/${id}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.episodes;
        setData(result);
        // console.log('orders', result);
        const seriesData = response?.data?.SeriesSeason?.access;
        setSeries_user(seriesData);
      })
      .catch((error) => console?.log(error));
  }, []);


  useEffect(() => {
    // Filter useraccessseason based on series value
    let options = [];
    switch (series_user) {
      case 'guest':
        options = user_access; // Show all options for guest
        break;
      case 'subscriber':
        options = user_access?.filter(option => ['subscriber', 'ppv'].includes(option.role || option.value));
        break;
      case 'registered':
        options = user_access?.filter(option => ['registered', 'subscriber', 'ppv'].includes(option.role || option.value));
        break;
      case 'ppv':
        options = user_access?.filter(option => ['subscriber', 'ppv'].includes(option.role || option.value));
        break;
      default:
        options = []; // No options for unknown series
    }
    setFilteredOptions(options);
  }, [series_user, user_access]);

  const [validationMessagefree_duration_time, setValidationMessagefree_duration_time] = useState('');
  const [isValidationHiddenfree_duration_time, setIsValidationHiddenfree_duration_time] = useState(true);

  const free_duration_timeInputRef = useRef(null);

  const handleInputdatatime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const handleInputenabletime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.checked ? 1 : 0;

    setEditUser({
      ...editUser,
      [inputName]: inputValue,
    });
  };


  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguage, setValidationMessagelanguage] =
    useState("");
  const [isValidationHiddenlanguage, setIsValidationHiddenlanguage] =
    useState(true);


  const [validationMessageAccess, setValidationMessageAccess] =
    useState("");
  const [isValidationHiddenAccess, setIsValidationHiddenAccess] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);
  const accessInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function Manageepisode() {
    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (selectedOptionscategory?.length === 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }


    // Reset validation messages
    setValidationMessagefree_duration_time("");
    setIsValidationHiddenfree_duration_time(true);

    if (editUser?.free_duration_status === 1) {
      // Validate free_duration_time only if free_duration_status is active (1)
      if (!editUser?.free_duration_time) {
        setValidationMessagefree_duration_time("If Free Duration Status is active, Free Duration Time is mandatory.");
        setIsValidationHiddenfree_duration_time(false);
        focusInputRef = free_duration_timeInputRef;
        return;
      }

      // Additional validation for free_duration_time format can be added here if needed
      // e.g., regex check for HH:MM:SS format
    }

    let valid = true;

    if (compressResolutionFormat?.episode === 1) {
      if (!selectedFile1) {
        setError(prevErrors => ({ ...prevErrors, file1: 'Image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile2) {
        setError(prevErrors => ({ ...prevErrors, file2: 'Player image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile3) {
        setError(prevErrors => ({ ...prevErrors, file3: 'Video TV image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }
    }

    if (!storeliveuseraccess) {
      setValidationMessageAccess("Please select user access.");
      setIsValidationHiddenAccess(false);
      focusInputRef = accessInputRef;
    }

    if (!valid) {
      return;
    }


    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    const editInputfeatured = featured?.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputbanner = banner?.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputvalue = active?.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputvaluefooter = footer?.footer;
    var data = JSON.stringify(editInputvaluefooter);
    var footerdata = data;

    const editInputfree_duration_statusdata =
      free_duration_status?.free_duration_status;
    var data = JSON.stringify(editInputfree_duration_statusdata);
    var free_duration_statusdata = data;

    const formData = new FormData();

    formData.append("series_id", seriesid);
    formData.append("season_id", id);
    formData.append("id", episode_id);
    formData.append("episode_id", episode_id);
    formData.append("title", editUser?.title);
    formData.append("slug", autoFillValuetitle);
    formData.append("ads_category", editUser?.ads_category);
    formData.append("mid_advertisement_sequence_time", editUser?.mid_advertisement_sequence_time);
    // formData.append("episode_description", episode_description);
    formData.append("episode_description", description);
    formData.append("type", type);
    formData.append("access", storeliveuseraccess);

    const enableppv_status = Number.isNaN(parseInt(inputValue, 10)) ? 0 : parseInt(inputValue, 10);
    formData.append("ppv_status", enableppv_status);
    // formData.append("ppv_status", inputValue);
    formData.append("ios_ppv_price", editUser?.ios_ppv_price);
    formData.append("global_ppv", checkboxChecked === true ? 1 : 0 || editUser?.global_ppv);
    // formData.append("ppv_price", inputValue);
    if (global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0) {
      formData.append("ppv_price", inputValue);
    } else {
      formData.append("ppv_price", datanewppvpprice?.price);
    }
    formData.append("mp4_url", mp4_url);
    formData.append("url", url);
    formData.append("path", path);
    formData.append("disk", disk);
    formData.append("stream_path", stream_path);
    formData.append("processed_low", processed_low);
    formData.append("converted_for_streaming_at", converted_for_streaming_at);
    // formData.append("active", aactivedata2 ? parseInt(aactivedata2, 10) : 0);

    const aactivedata2 = Number.isNaN(parseInt(aactivedata, 10)) ? 0 : parseInt(aactivedata, 10);
    formData.append("active", aactivedata2 ? parseInt(aactivedata2, 10) : 0);

    formData.append("year", editUser?.year);

    const enablefree_duration_statusdata = Number.isNaN(parseInt(free_duration_statusdata, 10)) ? 0 : parseInt(free_duration_statusdata, 10);
    formData.append("free_duration_status", enablefree_duration_statusdata);
    formData.append("free_duration_time", editUser?.free_duration_time);

    formData.append(
      "artist_id",
      JSON.stringify(selectedOptionsartists?.map((option) => option?.value))
    );
    formData.append(
      "block_country_id",
      JSON.stringify(selectedOptions?.map((option) => option?.value))
    );
    formData.append(
      "available_countries_id",
      JSON.stringify(
        selectedOptionsAvailable_country?.map((option) => option?.value)
      )
    );
    formData.append(
      "category_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option?.value))
    );
    formData.append(
      "language_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option?.value))
    );
    formData.append("age_restrict", age_restrict);
    formData.append(
      "related_videos",
      JSON.stringify(
        selectedOptionsRelated_videos?.map((option) => option?.value)
      )
    );

    // formData.append("skip_recap", skip_recap);
    formData.append("url_linktym", insertColons(userTime?.url_linktym));

    formData.append("skip_start_time", userTime?.skip_start_time);
    formData.append("skip_end_time", userTime?.skip_end_time);
    formData.append("skip_start_session", userTime?.skip_start_session);
    formData.append("recap_start_time", userTime?.recap_start_time);
    formData.append("recap_end_time", userTime?.recap_end_time);
    formData.append("recap_start_session", userTime?.recap_start_session);

    // formData.append("recap_start_time", recap_start_time);
    // formData.append("recap_end_time", recap_end_time);
    // formData.append("intro_start_time", intro_start_time);
    // formData.append("intro_end_time", intro_end_time);

    const enablefeatureddata = Number.isNaN(parseInt(featureddata, 10)) ? 0 : parseInt(featureddata, 10);
    formData.append("featured", enablefeatureddata);
    const enablebannerdata = Number.isNaN(parseInt(bannerdata, 10)) ? 0 : parseInt(bannerdata, 10);
    formData.append("banner", enablebannerdata);
    const enablefooterdata = Number.isNaN(parseInt(footerdata, 10)) ? 0 : parseInt(footerdata, 10);
    formData.append("footer", enablefooterdata);
    formData.append("duration", editUser?.duration);
    formData.append("age_restrict", age_restrict);

    const enableviews = Number.isNaN(parseInt(views, 10)) ? 0 : parseInt(views, 10);
    formData.append("views", enableviews ? parseInt(enableviews, 10) : 0);
    const enablerating = Number.isNaN(parseInt(rating, 10)) ? 0 : parseInt(rating, 10);
    formData.append("rating", enablerating);
    const enablestatus = Number.isNaN(parseInt(status, 10)) ? 0 : parseInt(status, 10);
    formData.append("status", 1);
    formData.append("free_content_duration", free_content_duration);
    formData.append("search_tags", searchTags);
    formData.append("uploaded_by", uploaded_by);
    formData.append("ads_position", ads_position);
    formData.append("episode_ads", episode_ads);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;
    let fourthApiStatus = false;

    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/episode/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;

        resultapi = response?.data;

        const imageFormData = new FormData();
        imageFormData.append("episode_id", episode_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          imageFormData.append("tv_image", selectedFile3);
        }
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-episode`,
          imageFormData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("episode_id", episode_id);
        formDataseo.append("website_page_title", websitetitle);
        formDataseo.append("website_URL", websiteurl);
        formDataseo.append("Meta_description", metadescription);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/episode/SEO`,
          formDataseo,
          { headers: headers }
        );

        const formDatasubtitles = new FormData();
        formDatasubtitles.append("episode_id", episode_id);

        Object.keys(videosubtitles).forEach((language, index) => {
          formDatasubtitles.append(
            `short_code[${index}]`,
            Subtitlesmap[index].short_code
          );
          formDatasubtitles.append(
            `language[${index}]`,
            Subtitlesmap[index].language
          );
          videosubtitles[language].forEach((file) => {
            formDatasubtitles.append(`subtitles[${index}]`, file);
          });
        });

        const formDatasubtitlesresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/episode/subtitles`,
          formDatasubtitles,
          { headers: headers }
        );


        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse?.data?.status === true) {
          seventhApiStatus = true;
        }
        if (formDatasubtitlesresponse?.data?.status === true) {
          fourthApiStatus = true;
        }
      } else if (response?.data?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus && seventhApiStatus && fourthApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      // Check if resultapifalse is defined and not null
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
      setProcessing(false);
      setShowOverlay(false);
    }
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    if (selectedAdsPosition !== "") {
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response?.data;
          // console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  // const [livesource, setLivesource] = useState("");

  // function live_stream_source() {
  //   switch (livesource) {
  //     case "guest":
  //       return <div></div>;
  //     case "subscriber":
  //       return <div></div>;
  //     case "registered":
  //       return <div></div>;
  //     case "ppv":
  //       return (
  //         <div className="row mt-3 text-start ">
  //           <div className="col-sm-6">
  //             <label className="m-0">PPV Price</label>
  //             <div className="col-lg-12 row">
  //               <div className="col-8 col-lg-8">
  //                 <p className="p1">Apply PPV Price from Global Settings? </p>
  //               </div>
  //               <div className="col-4 col-lg-4">
  //                 <label className="switch">
  //                   <input
  //                     name="ppv_price"
  //                     defaultChecked={checkboxChecked}
  //                     onChange={handleCheckboxChange}
  //                     type="checkbox"
  //                   />
  //                   <span
  //                     className="slider round"
  //                     name="ppv_price"
  //                     checked={checkboxChecked}
  //                     onChange={handleCheckboxChange}
  //                   ></span>
  //                 </label>
  //               </div>
  //             </div>

  //             <div className="panel-body">
  //               <input
  //                 type="number"
  //                 className="form-control"
  //                 placeholder="PPV Price"
  //                 name="ppv_price"
  //                 id="price"
  //                 value={inputValue}
  //                 onChange={handleInputChange}
  //               />
  //             </div>
  //           </div>

  //           <div className="col-sm-6">
  //             <label className="m-0"> IOS PPV Price</label>
  //             <p className="p1">Apply IOS PPV Price from Global Settings?</p>
  //             <div className="panel-body ppv_price_ios">
  //               <select
  //                 onChange={(e) => setIos_ppv_price(e.target.value)}
  //                 className="form-select"
  //                 id="ios_ppv_price"
  //                 name="ios_ppv_price"
  //               >
  //                 <option value="">Choose a PPV Price</option>
  //                 {inapppurchase?.map((item, key) => (
  //                   <option value={item?.product_id}>{item?.plan_price}</option>
  //                 ))}
  //               </select>
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     default:
  //       return null;
  //   }
  // }

  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <label className="m-0">Publish Time</label>
            <input
              type="datetime-local"
              className="rs-input mt-2  form-control-lg"
              id="publish_time"
              name="publish_time"
              onChange={(e) => setPublish_time(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  }

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <>
            {global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0 ? (

              <div className="row mt-3 text-start ">
                <div className="col-sm-12">
                  <label className="m-0">{label_management?.series_season_episode[55]?.title}</label>
                  <div className="col-lg-12 row">
                    <div className="col-8 col-lg-8">
                      <p className="p1">{label_management?.series_season_episode[55]?.sub_title} </p>
                    </div>
                    <div className="col-4 col-lg-4">
                      <label className="switch">
                        <input
                          name="ppv_price"
                          defaultChecked={checkboxChecked}
                          onChange={handleCheckboxChange}
                          type="checkbox"
                          className="rs-input"
                        />
                        <span
                          className="slider round"
                          name="ppv_price"
                          checked={checkboxChecked}
                          onChange={handleCheckboxChange}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="panel-body">
                    <input
                      type="number"
                      className="rs-input"
                      placeholder="PPV Price"
                      id="ppv_price"
                      name="ppv_price"
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-sm-12 mt-2">
                  <label className="m-0">{label_management?.series_season_episode[51]?.title}</label>
                  <p className="p1">{label_management?.series_season_episode[51]?.sub_title}</p>
                  <div className="panel-body ppv_price_ios mt-2">
                    <select
                      name="ios_plan_price"
                      id="ios_ppv_price"
                      onChange={handleInput}
                      value={editUser?.ios_plan_price}
                      className="form-select"
                    >
                      <option value="">Choose a PPV Price</option>
                      {inapppurchase?.map((item, key) => (
                        <option value={item?.product_id}>{item?.plan_price}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ) :
              <div className="mt-2">
                <div className="col-sm-12">
                  <div className='modalofglobalppv'>
                    <Form>
                      <>
                        {global_ppv_status_active?.ppv_status === "1" || global_ppv_status_active?.ppv_status === 1 ? (
                          <Form.Check
                            type="radio"
                            label={label_management?.series_season_episode[47]?.title}
                            name="priceOption"
                            value="1"
                            checked={editUser?.global_ppv === "1" || editUser?.global_ppv === 1}
                            // onChange={handleChange}
                            onClick={() => handleChange({ target: { value: '1' } })}
                          />
                        ) : null}
                      </>
                      <Form.Check
                        type="radio"
                        label={label_management?.series_season_episode[52]?.title}
                        name="priceOption"
                        value="2"
                        checked={editUser?.global_ppv === null || (editUser?.global_ppv !== "1" && editUser?.global_ppv !== 1)}
                        // onChange={handleChange}
                        onClick={() => handleChange({ target: { value: '2' } })}
                      />
                    </Form>
                    <Modal show={showModal} onHide={handleClose} dialogClassName="modal-lg modal-dialog-scrollable" backdrop="static"
                      keyboard={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>{modalType === '1' ? `${label_management?.series_season_episode[48]?.title}` : `${label_management?.series_season_episode[53]?.title}`}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-body-custom">
                        {step === 1 ? (
                          <Form>
                            <Form.Group>
                              <Form.Label
                                className="mandatorySimple">{modalType === '1' ? `${label_management?.series_season_episode[49]?.title}` : `${label_management?.series_season_episode[54]?.title}`}</Form.Label>
                              <Form.Control
                                type="number"
                                name="price"
                                value={modalType === '1' ? formDataglobalprice.price : formDatacustomprice.price}
                                onChange={(e) => handlePriceChange(e, modalType === '1' ? 'global' : 'custom')}
                                disabled={modalType === '1'}
                              />
                            </Form.Group>
                            {modalType === '2' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.series_season_episode[50]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {modalType === '1' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.series_season_episode[50]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {/* <div className='text-end mt-2'>
             <Button variant="primary" onClick={handleNext}>
               Next
             </Button>
           </div> */}

                            <div className="col-sm-12 mt-2">
                              <label className="m-0">{label_management?.series_season_episode[51]?.title}</label>
                              <p className="p1">{label_management?.series_season_episode[51]?.sub_title}</p>
                              <div className="panel-body ppv_price_ios mt-2">
                                <select
                                  name="ios_ppv_price"
                                  id="ios_ppv_price"
                                  onChange={handleInput}
                                  value={editUser?.ios_ppv_price}
                                  className="form-select"
                                >
                                  <option value="">Select IOS Price</option>
                                  {inapppurchase?.map((item, key) => (
                                    <option value={item?.product_id}>{item?.plan_price}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                          </Form>
                        ) : (
                          <>
                            <div className="table-responsive">
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Country</th>
                                    <th>Current Price</th>
                                    <th>Converted Price</th>
                                    <th>Difference</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices).map((item, index) => (
                                    <tr key={index}>
                                      <td>{item?.country_name || item?.Country} ({item?.Code})</td>
                                      <td>{item?.Symbol || item?.symbol} {item?.price || item?.ActualPrice}</td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          value={item?.editPrice !== undefined ? item?.editPrice : item?.convertedPrice}
                                          onChange={(e) => handleInputChangeeditable(index, 'editPrice', e.target.value)}
                                          disabled={modalType === '1'}
                                        />
                                      </td>
                                      <td>{item?.difference || item?.RateDifference}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            {/* <div className='text-end mt-2'>
             <Button variant="secondary" onClick={() => setStep(1)}>
               Previous
             </Button>
             <Button variant="primary" onClick={handleSubmit}>
               Submit
             </Button>
           </div> */}
                          </>
                        )}
                      </Modal.Body>

                      <hr></hr>
                      {step === 1 ? (

                        <div className='text-end p-2'>
                          <Button variant="primary" onClick={handleNext}>
                            Next
                          </Button>
                        </div>
                      ) : (
                        <div className='text-end p-2'>
                          <Button variant="secondary" onClick={() => setStep(1)}>
                            Previous
                          </Button>
                          <Button variant="primary" onClick={handleSubmit} className='ms-2'>
                            Submit
                          </Button>
                        </div>
                      )}
                    </Modal>
                  </div>
                </div>
              </div>
            }
          </>
        );
      default:
        return null;
    }
  }

  const fetchDatanextpre = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/series_season/edit/${seriesid}/${id}?page=${page}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const videosData = res?.data?.episodes;
        setVideodata(videosData);
        if (videosData?.length > 0) {
          setData(videosData);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/episode/index`,
        { headers: headers }
      );
      setCount(response?.data?.Total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchdefaultimage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/Vertical-Default-Image`,
        { headers: headers }
      );
      setDefaultimage(response?.data?.Vertical_Default_Image);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchdefaultimageurl = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/Default-image-url`,
        { headers: headers }
      );
      setDefaultimageurl(response?.data?.Default_Image_Url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [showModalmodal, setshowModalmodal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const handleToggle = async (item) => {
    setCurrentItem(item);
    setshowModalmodal(true);
  };

  const confirmToggle = async () => {
    try {
      setshowModalmodal(false);
      setShowOverlay(true)
      const newStatus = currentItem?.banner === 1 ? 0 : 1;
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/episode/slider-update`,
        {
          method: "POST",
          body: JSON.stringify({
            episode_id: currentItem?.id,
            banner_status: newStatus,
          }),
          headers: headers,
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        const updatedItems = series?.map((currItem) => {
          if (currItem.id === currentItem?.id) {
            return { ...currItem, banner: newStatus };
          }
          return currItem;
        });
        setSeries(updatedItems);
        props?.setApiresponsealert(responseData);
        props?.setShowalert(true);
        setMessage(responseData?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        props?.setApiresponsealert(responseData);
        props?.setShowalert(true);
      }
    } catch (error) { }
  };

  const closeModalslider = () => {
    setshowModalmodal(false);
    setCurrentItem(null);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePreClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNxtClick = () => {
    setPage(page + 1);
  };

  const videosdeleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/admin/episode/delete/` + id, {
        headers: headers,
      })
      .then((response) => {
        if (response?.data?.status == true) {
          var responseData = response?.data;
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props?.setApiresponsealert(resultError);
            props?.setShowalert(true);
          }
        }
      });
  };

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSuccessMessageOpen, setIsSuccessMessageOpen] = useState(false);

  const handleSingleSelect = (itemId) => {
    setSelectedItems([itemId]);
    setSelectAll(false);
  };

  const handleMultiSelect = (itemId) => {
    if (selectedItems?.includes(itemId)) {
      setSelectedItems(selectedItems?.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedItems(selectAll ? [] : videodata?.map((item) => item?.id));
  };

  const handleDeletemulti = () => {
    setIsConfirmationOpen(true);
  };
  const handleCancelDelete = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmDelete = () => {
    setIsConfirmationOpen(false);
    for (const itemId of selectedItems) {
      axios
        .delete(
          `${process.env.REACT_APP_Baseurl}/admin/episode/delete/${itemId}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response?.data?.status == true) {
            setVideodata(videodata?.filter((item) => item?.id !== itemId));
            setSelectedItems(selectedItems?.filter((id) => id !== itemId));
            setIsSuccessMessageOpen(true);
            setTimeout(() => {
              setIsSuccessMessageOpen(false);
            }, 5000);
            var responseData = response?.data;
            props?.setApiresponsealert(responseData);
            props?.setShowalert(true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            if (responseData === false) {
              var resultError = responseData;
              props?.setApiresponsealert(resultError);
              props?.setShowalert(true);
            }
          }
        });
    }
  };

  const [itemToDelete, setItemToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const openModal = () => {
    setModelClose(true);
  };
  const closeModal = () => {
    setItemToDelete(null);
    setModelClose(!closeModal);
  };
  const handleDeletedata = () => {
    closeModal();
    if (itemToDelete) {
      videosdeleteOperation(itemToDelete);
    }
  };

  useEffect(async () => {
    fetchData();
    fetchdefaultimage();
    fetchdefaultimageurl();
    fetchDatanextpre();
  }, [page]);





  // { Master Library data}
  // { Master Library data}

  const [totalMastercount, setTotalMastercount] = useState();
  const [videoData, setVideoData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [liveData, setLiveData] = useState([]);
  const [episodeData, setEpisodeData] = useState([]);

  const [filteredVideoData, setFilteredVideoData] = useState([]);
  const [filteredAudioData, setFilteredAudioData] = useState([]);
  const [filteredLiveData, setFilteredLiveData] = useState([]);
  const [filteredEpisodeData, setFilteredEpisodeData] = useState([]);

  const [selectAllVideo, setSelectAllVideo] = useState(false);
  const [selectAllAudio, setSelectAllAudio] = useState(false);
  const [selectAllLive, setSelectAllLive] = useState(false);
  const [selectAllEpisode, setSelectAllEpisode] = useState(false);

  const [filterVideo, setFilterVideo] = useState("");
  const [filterAudio, setFilterAudio] = useState("");
  const [filterLive, setFilterLive] = useState("");
  const [filterEpisode, setFilterEpisode] = useState("");

  const [itemToDeleteVideo, setItemToDeleteVideo] = useState([]);
  const [itemToDeleteAudio, setItemToDeleteAudio] = useState([]);
  const [itemToDeleteLive, setItemToDeleteLive] = useState([]);
  const [itemToDeleteEpisode, setItemToDeleteEpisode] = useState([]);

  const [modelClose, setModelClose] = useState(false);
  const [modalCloseLive, setModalCloseLive] = useState(false);
  const [modalCloseAudio, setModalCloseAudio] = useState(false);
  const [modalCloseEpisode, setModalCloseEpisode] = useState(false);

  const fetchApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/all-library/index`, {
        headers: headers,
      })
      .then((response) => {
        // const masterAllList = response;
        const videoList = response?.data?.Videos;
        const audioList = response?.data?.Audios;
        const liveList = response?.data?.LiveStream;
        const episodeList = response?.data?.episodes;
        // setMasterOverAllData(masterAllList);
        setTotalMastercount(response?.data?.master_count);
        const updatedVideoList = videoList?.map((item) => {
          const UploadStatus =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1 &&
              item?.publish_type === "publish_now" &&
              item?.status === 1 &&
              item?.draft === 1
              ? "PUBLISHED"
              : item?.active == null &&
                item?.publish_type === "publish_later" &&
                item?.status == null &&
                item?.draft == null
                ? "SCHEDULED"
                : "DRAFT";

          return { ...item, uploaded_by: UploadStatus, active: statusText };
        });
        const updatedLiveList = liveList?.map((item) => {
          const UploadStatus =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1 &&
              item?.publish_type === "publish_now" &&
              item?.status === 1
              ? // &&
              // item?.draft === 1
              "PUBLISHED"
              : item?.active == null &&
                item?.publish_type === "publish_later" &&
                item?.status == null
                ? //  &&
                // item?.draft == null
                "SCHEDULED"
                : "DRAFT";

          return { ...item, uploaded_by: UploadStatus, active: statusText };
        });
        const updatedAudioList = audioList?.map((item) => {
          const uploadBy =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1
              ? "PUBLISHED"
              : item?.active === 0 || item?.active === null
                ? "UNPUBLISHED"
                : "DRAFT";

          return { ...item, uploaded_by: uploadBy, active: statusText };
        });
        const updatedEpisodeList = episodeList?.map((item) => {
          const uploadBy =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1
              ? "PUBLISHED"
              : item?.active === 0 || item?.active === null
                ? "UNPUBLISHED"
                : "DRAFT";

          return { ...item, uploaded_by: uploadBy, active: statusText };
        });
        setVideoData(updatedVideoList);
        setAudioData(updatedAudioList);
        setLiveData(updatedLiveList);
        setEpisodeData(updatedEpisodeList);
        setFilteredVideoData(updatedVideoList);
        setFilteredLiveData(updatedLiveList);
        setFilteredAudioData(updatedAudioList);
        setFilteredEpisodeData(updatedEpisodeList);
        setLoading(false);
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  useEffect(async () => {
    fetchApi();
  }, []);
  ///General Pagination
  const commonPaginationConfig = (sizePerPageList) => {
    return paginationFactory({
      page: 1,
      sizePerPage: 10,
      nextPageText: "Next",
      prePageText: "Prev",
      firstPageText: <MdKeyboardDoubleArrowLeft />,
      lastPageText: <MdKeyboardDoubleArrowRight />,
      showTotal: true,
      alwaysShowAllBtns: true,
      sizePerPageList: sizePerPageList,
    });
  };
  const videoSizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredVideoData?.length },
  ];
  const liveSizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredLiveData?.length },
  ];
  const audioSizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredAudioData?.length },
  ];
  const episodeSizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredEpisodeData?.length },
  ];

  ////  Video Started

  const handleClear = () => {
    fetchApi();
    setFilterVideo("");
  };
  const handleFilterChange = (event) => {
    const searchTerm = event.target.value;
    setFilterVideo(searchTerm);
    const searchTermLower = searchTerm?.toLowerCase();
    const filteredResults = videoData?.filter((item) => {
      return (
        (typeof item?.title === "string" &&
          item?.title.toLowerCase().includes(searchTermLower)) ||
        item?.uploaded_by.toLowerCase().includes(searchTermLower) ||
        (typeof item?.active === "string" &&
          item?.active.toLowerCase().includes(searchTermLower)) ||
        item?.upload_on.toLowerCase().includes(searchTermLower)
      );
    });
    setFilteredVideoData(filteredResults);
  };
  const conditionalGeneratePDF = (get) => {
    const doc = new jsPDF();
    doc.text("VIDEO-LIST", 10, 10);
    const columns = ["Index", "ID", "uploaded_by", "status", "banner"];
    const allDataPDF = videoData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.uploaded_by,
      item?.active,
    ]);
    const filterPDF = filteredVideoData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.uploaded_by,
      item?.active,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save("VIDEO-LIST.pdf");
  };

  const generateXLSX = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? videoData : filteredVideoData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "VIDEO-LIST.xlsx");
  };

  const exportHeaders = [
    { label: "ID", key: "id" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "STATUS", key: "active" },
  ];


  const fileInputRefreplacevideo = useRef(null);

  const handleClickreplacevideo = (id) => (e) => {
    e.preventDefault();
    // Trigger the file input dialog
    fileInputRefreplacevideo.current.click();
    console.log(id); // Here you have access to row.id
  };

  const handleFileChangereplacevideo = async (event, id) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('video_id', id);

    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/edit-video-upload-file`,
        formData,
        {
          headers: headers, // Ensure headers is coming from props or context
        }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);

      const errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);

      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const handleFileChangereplacevideodelete = async (id) => {

    const formData = new FormData();
    // formData.append('file', file);
    // formData.append('video_id', id);

    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_Baseurl}/admin/videos-delete/${id}`,
        {
          headers: headers, // Ensure headers is coming from props or context
        }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);

      const errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);

      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const actionFormatter = (data, row) => {
    return (
      <>
        <div className="d-flex">
          <div>
            <div onClick={handleClickreplacevideo(row.id)} className="commonActionPadding">
              <span>
                <img src={retry_video} alt="flogo" width={20} height={20} />
              </span>
            </div>
            <input
              type="file"
              accept="video/*"
              style={{ display: 'none' }}
              ref={fileInputRefreplacevideo}
              onChange={(e) => handleFileChangereplacevideo(e, row.id)}
            />
          </div>
          <div>
            <div
              onClick={() => handleFileChangereplacevideodelete(row?.id)}
              className="commonActionPadding"
            >
              <img src={deleteitem} alt="flogo" width={20} height={20} />
            </div>
          </div>
        </div>

        {/* <div className="editdropdown">
          <span className="editdropdown-button">
            <span>
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </span>
          </span>
          <div className="editdropdown-menu">

            <div onClick={handleClickreplace(row.id)} className="commonActionPadding">
              <span>
                <img src={edititem} alt="flogo" width={20} height={20} />
                <span className="ms-2">Replace Video</span>
              </span>
            </div>
            <input
              type="file"
              accept="video/*"
              style={{ display: 'none' }}
              ref={fileInputRefreplace}
              onChange={(e) => handleFileChangereplace(e, row.id)}
            />
            <div
              onClick={() => singleDelete(row?.id)}
              className="commonActionPadding"
            >
              <img src={deleteitem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Delete Video</span>
            </div>
          </div>
        </div> */}
      </>
    );
  };

  const videoFormatter = (data, row) => {
    const truncatedData = row?.title
      ? (row?.title.length > 15 ? row.title.slice(0, 15) + '...' : row?.title)
      : '';
    return (
      <div className="d-flex justify-content-between align-items-center">
        <img src={data} className="profile" alt="image_all" />
        <div className="rightVideoTitle ms-2">
          <p>{truncatedData}</p>
          <span className="me-1 text-dark dateText ">{row?.duration}</span>
          <div><span className="dateText"> {row?.upload_on}</span></div>
          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/videos/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const statusFormatter = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "SCHEDULED"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };
  const columns = [
    {
      dataField: "Thumbnail",
      text: "Videos",
      formatter: videoFormatter,
      sort: true,
    },
    {
      dataField: "uploaded_by",
      text: "Upload by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusFormatter,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteVideo([...itemToDeleteVideo, row?.id]);
        } else {
          setItemToDeleteVideo(
            itemToDeleteVideo.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteVideo,
    selectionHeaderRenderer: ({ mode, checked }) => (
      <div>
        <input type="checkbox" checked={checked} onChange={handleAll} />
      </div>
    ),
  };
  const closeModalvideo = () => {
    setModelClose(!closeModalvideo);
  };
  const openModalvideo = () => {
    setModelClose(true);
  };
  const singleDelete = (id) => {
    setModelClose(true);
    setItemToDeleteVideo([id]);
  };
  const handleAll = () => {
    setSelectAllVideo(!selectAllVideo);
    setItemToDeleteVideo(
      selectAllVideo ? [] : filteredVideoData?.map((item) => item?.id)
    );
  };
  const handleDeleteVideoDatavideo = () => {
    handleConfirmDeletevideo();
    closeModalvideo();
  };
  const handleConfirmDeletevideo = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("video_id", "[" + itemToDeleteVideo + "]");
    formData.append("series_id", seriesid);
    formData.append("season_id", id);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/all-library/storeEpisodes`,
        formData,
        { headers: headers }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  //// video code end

  ///Live Stream Started
  const selectRowLive = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteLive([...itemToDeleteLive, row?.id]);
        } else {
          setItemToDeleteLive(itemToDeleteLive?.filter((id) => id !== row?.id));
        }
      }
    },
    selected: itemToDeleteLive,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAllLive}
        />
      </div>
    ),
  };
  const liveFormatter = (data, row) => {
    const truncatedData = data
      ? (data.length > 15 ? data.slice(0, 15) + '...' : data)
      : '';

    return (
      <div className="d-flex justify-content-between align-items-center">
        <img src={row?.Thumbnail} className="profile" alt="image_all" />
        <div className="rightVideoTitle ms-2">
          <p>{truncatedData}</p>
          <span className="me-1 text-dark dateText"> {row?.duration}</span>
          <div><span className="dateText">{row?.upload_on}</span></div>
          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/live/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const statusLiveFormatter = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "SCHEDULED"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };

  const actionLiveFormatter = (data, row) => {
    return (
      <div className="editdropdown text-start">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit/add-livestream/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Edit Live</span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteLive(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2"> Delete Live</span>
          </div>
        </div>
      </div>
    );
  };

  const liveColumns = [
    {
      dataField: "title",
      text: "Live Videos",
      sort: true,
      formatter: liveFormatter,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusLiveFormatter,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionLiveFormatter,
    },
  ];

  const exportHeadersLive = [
    { label: "ID", key: "id" },
    { label: "VIDEOS", key: "title" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "STATUS", key: "active" },
    { label: "SLIDER", key: "banner" },
    { label: "UPLOAD ON", key: "upload_on" },
  ];
  const conditionalGeneratePDFLive = (get) => {
    const doc = new jsPDF();
    doc.text("ALL LIVE LIST ", 10, 10);
    const columns = [
      "Index",
      "ID",
      "VIDEOS",
      "UPLOADED BY",
      "STATUS",
      "BANNER",
      "UPLOAD ON",
    ];
    const allDataPDF = liveData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.uploaded_by,
      item?.active,
      item?.banner,
      item?.upload_on,
    ]);
    const filterPDF = filteredLiveData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.uploaded_by,
      item?.active,
      item?.banner,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save("ALL-LIVE-LIST.pdf");
  };
  const generateXLSXLIVE = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? liveData : filteredLiveData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "ALL-LIVE-LIST.xlsx");
  };
  const handleFilterChangeLive = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilterLive(searchTerm);
    const filteredResults = liveData.filter((item) =>
      ["id", "title", "uploaded_by", "active", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredLiveData(filteredResults);
  };
  const handleClearLive = () => {
    fetchApi();
    setFilterLive("");
  };
  const closeLiveModal = () => {
    setModalCloseLive(!modalCloseLive);
  };
  const openModalSingleDeleteLive = (id) => {
    setItemToDeleteLive([id]);
    setModalCloseLive(true);
  };
  const handleDeleteLiveData = () => {
    closeLiveModal();
    liveDeleteOperation();
  };
  const openModalMultiDeleteLive = () => {
    setModalCloseLive(true);
  };

  const handleAllLive = () => {
    setSelectAllLive(!selectAllLive);
    setItemToDeleteLive(
      selectAllLive ? [] : filteredLiveData?.map((item) => item?.id)
    );
  };

  const liveDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteLive + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/multi-delete/`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  ////Live Stream End

  ////Audio started
  const selectRowAudio = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteAudio([...itemToDeleteAudio, row?.id]);
        } else {
          setItemToDeleteAudio(
            itemToDeleteAudio.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteAudio,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAllAudio}
        />
      </div>
    ),
  };

  const audioFormatter = (data, row) => {

    const truncatedData = data
      ? (data.length > 15 ? data.slice(0, 15) + '...' : data)
      : '';

    return (
      <div className="d-flex justify-content-between align-items-center">
        <img
          src={row?.TV_Thumbnail ? row?.TV_Thumbnail : defaultImage}
          className="profile"
          alt="image_all"
        />

        <div className="rightVideoTitle ms-2">
          <p>{truncatedData}</p>
          <span className="me-1 text-dark dateText"> {row?.duration}</span>
          <div><span className="dateText">{row?.upload_on}</span></div>
          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/music/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const statusFormatterAudio = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "DRAFT"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };


  const fileInputRefreplaceaudio = useRef(null);

  const handleClickreplaceaudio = (id) => {
    return (e) => {
      e.preventDefault();
      if (fileInputRefreplaceaudio.current) {
        fileInputRefreplaceaudio.current.click();
      } else {
        console.error('fileInputRefreplaceaudio is not assigned properly.');
      }
    };
  };


  const handleFileChangereplaceaudio = async (event, id) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('audio_id', id); // Append row.id to the formData

    // Set processing and show overlay states to true
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/edit-audio-upload-file`,
        formData,
        {
          headers: headers,
        }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);

      const errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);

      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const handleFileChangereplaceaudiodelete = async (id) => {

    const formData = new FormData();
    // formData.append('file', file);
    // formData.append('video_id', id);

    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_Baseurl}/admin/audio/delete/${id}`,
        {
          headers: headers, // Ensure headers is coming from props or context
        }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);

      const errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);

      setProcessing(false);
      setShowOverlay(false);
    }
  };



  const actionFormatterAudio = (data, row) => {
    return (
      <>
        <div className="d-flex">
          <div>
            <div onClick={handleClickreplaceaudio(row.id)} className="commonActionPadding">
              <span>
                <img src={retry_video} alt="flogo" width={20} height={20} />
              </span>
            </div>
            <input
              type="file"
              accept="audio/*"
              style={{ display: 'none' }}
              ref={fileInputRefreplaceaudio}
              onChange={(e) => handleFileChangereplaceaudio(e, row.id)}
            />
          </div>
          <div>
            <div
              onClick={() => handleFileChangereplaceaudiodelete(row?.id)}
              className="commonActionPadding"
            >
              <img src={deleteitem} alt="flogo" width={20} height={20} />
            </div>
          </div>
        </div>


        {/* <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit-audio/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2"> Edit Audio </span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteAudio(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2"> Delete Audio</span>
          </div>
        </div>
      </div> */}
      </>

    );
  };
  const columnsAudio = [
    {
      dataField: "title",
      text: "Audios",
      formatter: audioFormatter,
      sort: true,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusFormatterAudio,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatterAudio,
    },
  ];
  const exportHeadersAudio = [
    { label: "ID", key: "id" },
    { label: "AUDIOS", key: "title" },
    { label: "DURATION", key: "duration" },
    { label: "STATUS", key: "active" },
    { label: "BANNER", key: "banner" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD-ON", key: "upload_on" },
  ];
  const conditionalGeneratePDFAudio = (get) => {
    const doc = new jsPDF();
    doc.text("ALL AUDIO LIST ", 10, 10);
    const columns = [
      "INDEX",
      "ID",
      "AUDIOS NAME",
      "DURATION",
      "STATUS",
      "BANNER",
      "UPLOADED BY",
      "UPLOAD-ON",
    ];
    const allDataPDF = audioData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.banner,
      item?.uploaded_by,
      item?.upload_on,
    ]);
    const filterPDF = filteredAudioData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.banner,
      item?.uploaded_by,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save(get === 1 ? "ALL-AUDIO-LIST.pdf" : "AUDIO-FILTERED-LIST");
  };

  const generateXLSXAUDIO = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? audioData : filteredAudioData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(
      wb,
      get === 1 ? "ALL-AUDIO-LIST.xlsx" : "AUDIO-FILTERED-LIST.xlsx"
    );
  };

  const handleFilterChangeAudio = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilterAudio(searchTerm);
    const filteredResults = audioData.filter((item) =>
      ["title", "duration", "active", "uploaded_by", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredAudioData(filteredResults);
  };

  const closeAudioModal = () => {
    setModalCloseAudio(!modalCloseAudio);
  };

  const openModalSingleDeleteAudio = (id) => {
    setItemToDeleteAudio([id]);
    setModalCloseAudio(true);
  };

  const openModalMultiDeleteAudio = () => {
    setModalCloseAudio(true);
  };

  const handleDeleteAudioData = () => {
    closeAudioModal();
    audioDeleteOperation();
  };

  const audioDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteAudio + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/all-library/storeAudios`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };
  const handleClearAudio = () => {
    fetchApi();
    setFilterAudio("");
  };
  const handleAllAudio = () => {
    setSelectAllAudio(!selectAllAudio);
    setItemToDeleteAudio(
      selectAllAudio ? [] : filteredAudioData?.map((item) => item?.id)
    );
  };

  ///Audio End

  /////Episode start
  const selectRowEpisode = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteEpisode([...itemToDeleteEpisode, row?.id]);
        } else {
          setItemToDeleteEpisode(
            itemToDeleteEpisode.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteEpisode,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAllEpisode}
        />
      </div>
    ),
  };

  const episodeFormatter = (data, row) => {

    const truncatedData = data
      ? (data.length > 15 ? data.slice(0, 15) + '...' : data)
      : '';

    return (
      <div className="d-flex justify-content-between align-items-center">
        <img
          src={row?.Thumbnail ? row?.Thumbnail : defaultImage}
          className="profile"
          alt="image_all"
        />

        <div className="rightVideoTitle ms-2">
          <p>{truncatedData}</p>
          <span className="me-1 text-dark dateText"> {row?.duration}</span>
          <div><span className="dateText">{row?.upload_on}</span></div>

          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/episode/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const statusFormatterEpisode = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "DRAFT"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };


  const fileInputRefreplaceepisode = useRef(null);

  const handleClickreplaceepisode = (id) => {
    return (e) => {
      e.preventDefault();
      if (fileInputRefreplaceepisode.current) {
        fileInputRefreplaceepisode.current.click();
      } else {
        console.error('fileInputRefreplaceepisode is not assigned properly.');
      }
    };
  };


  const handleFileChangereplaceepisode = async (event, id) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('episode_id', id);

    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Edit-Episode-Upload`,
        formData,
        {
          headers: headers,
        }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);

      const errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);

      setProcessing(false);
      setShowOverlay(false);
    }
  };


  const handleFileChangereplaceepisodedelete = async (id) => {

    const formData = new FormData();
    // formData.append('file', file);
    // formData.append('video_id', id);

    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_Baseurl}/admin/episode/delete/${id}`,
        {
          headers: headers, // Ensure headers is coming from props or context
        }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);

      const errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);

      setProcessing(false);
      setShowOverlay(false);
    }
  };


  const actionFormatterEpisode = (data, row) => {
    return (
      <>
        <div className="d-flex">
          <div>
            <div onClick={handleClickreplaceepisode(row.id)} className="commonActionPadding">
              <span>
                <img src={retry_video} alt="flogo" width={20} height={20} />
              </span>
            </div>
            <input
              type="file"
              accept="video/*"
              style={{ display: 'none' }}
              ref={fileInputRefreplaceepisode}
              onChange={(e) => handleFileChangereplaceepisode(e, row.id)}
            />
          </div>
          <div>
            <div
              onClick={() => handleFileChangereplaceepisodedelete(row?.id)}
              className="commonActionPadding"
            >
              <img src={deleteitem} alt="flogo" width={20} height={20} />
            </div>
          </div>
        </div>


        {/* <div className="editdropdown">
          <span className="editdropdown-button">
            <span>
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </span>
          </span>
          <div className="editdropdown-menu">
            <Link to={`/edit-episode/manage/${row?.id}`}>
              <span>
                <img src={edititem} alt="flogo" width={20} height={20} />
                <span className="ms-2"> Edit Episode </span>
              </span>
            </Link>

            <div
              onClick={() => openModalSingleDeleteEpisode(row?.id)}
              className="commonActionPadding"
            >
              <img src={deleteitem} alt="flogo" width={20} height={20} />
              <span className="ms-2"> Delete Episode</span>
            </div>
          </div>
        </div> */}
      </>

    );
  };
  const columnsEpisode = [
    {
      dataField: "title",
      text: "Episodes",
      formatter: episodeFormatter,
      sort: true,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusFormatterEpisode,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatterEpisode,
    },
  ];
  const exportHeadersEpisode = [
    { label: "ID", key: "id" },
    { label: "EPISODE", key: "title" },
    { label: "DURATION", key: "duration" },
    { label: "STATUS", key: "active" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD-ON", key: "upload_on" },
  ];
  const conditionalGeneratePDFEpisode = (get) => {
    const doc = new jsPDF();
    doc.text("ALL EPISODE LIST ", 10, 10);
    const columns = [
      "INDEX",
      "ID",
      "EPISODE NAME",
      "DURATION",
      "STATUS",
      "UPLOADED BY",
      "UPLOAD-ON",
    ];
    const allDataPDF = episodeData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.uploaded_by,
      item?.upload_on,
    ]);
    const filterPDF = filteredEpisodeData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.uploaded_by,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save(get === 1 ? "ALL-EPISODE-LIST.pdf" : "FILTERED-EPISODE-LIST");
  };

  const generateXLSXEPISODE = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(
        get === 1 ? episodeData : filteredEpisodeData
      );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(
      wb,
      get === 1 ? "ALL-EPISODE-LIST.xlsx" : "FILTERED-EPISODE-LIST.xlsx"
    );
  };

  const handleFilterChangeEpisode = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilterEpisode(searchTerm);
    const filteredResults = episodeData?.filter((item) =>
      ["title", "duration", "active", "uploaded_by", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredEpisodeData(filteredResults);
  };

  const closeEpisodeModal = () => {
    setModalCloseEpisode(!modalCloseEpisode);
  };

  const openModalSingleDeleteEpisode = (id) => {
    setItemToDeleteEpisode([id]);
    setModalCloseEpisode(true);
  };

  const openModalMultiDeleteEpisode = () => {
    setModalCloseEpisode(true);
  };

  const handleDeleteEpisodeData = () => {
    closeEpisodeModal();
    episodeDeleteOperation();
  };

  const episodeDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteEpisode + "]");
    formData.append("series_id", seriesid);
    formData.append("season_id", id);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/all-library/storeVideos`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };
  const handleClearEpisode = () => {
    fetchApi();
    setFilterEpisode("");
  };
  const handleAllEpisode = () => {
    setSelectAllEpisode(!selectAllEpisode);
    setItemToDeleteEpisode(
      selectAllEpisode ? [] : filteredEpisodeData?.map((item) => item?.id)
    );
  };

  // { Master Library data}
  // { Master Library data}


  // {ppv price with global and customs} 
  // {ppv price with global and customs} 

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [step, setStep] = useState(1);
  const [formDataglobalprice, setFormDataglobalprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for global price
    price: ppv_gobal_price,
    convertedPrices: []
  });
  const [formDatacustomprice, setFormDatacustomprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for custom price
    price: editUser?.ppv_price,
    convertedPrices: []
  });



  const handleChange = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: "1",
      }));
    } else if (value === "2") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: null,
      }));
    }

    setModalType(e.target.value);
    setShowModal(true);
    setStep(1);
  };

  const handleClose = () => {
    setShowModal(false);
    setFormDataglobalprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: ppv_gobal_price,
      convertedPrices: []
    });
    setFormDatacustomprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: editUser?.ppv_price,
      convertedPrices: []
    });
  };

  const handleNext = async () => {
    if (step === 1) {
      const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

      if (!formData?.priceType || !formData?.price) {
        toast.error('Please fill in the required fields.')
        return;
      }

      try {
        if (modalType === '2' && formData?.price !== editUser?.ppv_price) {
          const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${formData?.priceType}`);
          const rates = response?.data.rates;

          const convertedPrices = allCurrencylistfromappi?.map(item => {
            let country_name = item?.country;
            let Symbol = item?.Symbol || item?.symbol;
            let Code = item?.code;

            const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
            if (matchingCountry) {
              country_name = matchingCountry?.country;
              Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
            }

            return {
              Code: Code,
              country_name: country_name,
              Symbol: Symbol,
              convertedPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              price: formData?.price,
              difference: ((formData?.price * (rates[item?.code] || 0)) - formData?.price).toFixed(2),
              editPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              isEditable: true,
            };
          });

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: convertedPrices
          }));
        } else if (modalType === '2' && formData?.price === editUser?.ppv_price) {
          const customPrices = allCurrencycustoms?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            difference: item?.RateDifference || 0,
            isEditable: true
          }));

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: customPrices
          }));
        } else if (modalType === '1') {
          const globalPrices = allCurrencyglobal?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            isEditable: false
          }));
          setFormDataglobalprice(prevState => ({
            ...prevState,
            convertedPrices: globalPrices
          }));
        }

        setStep(2);
      } catch (error) {
        console.error('Error converting currency:', error);
      }
    }
  };


  const handleSubmit = async () => {
    const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

    if (!formData || formData?.convertedPrices.length === 0) {
      alert('Please fill in all the fields.');
      return;
    }

    // Prepare data to submit
    const dataToSubmit = {
      source_type: 4, // Note - 1 (Videos) or 2 (Livestream) or 3 (Series) or 4 (Episode) or 5 (Audios) or 6 season or 7 golbal
      source_id: episode_id,
      PPVType: modalType,
      PriceType: formData?.priceType, // Separate priceType outside of convertedPrices
      IOSPrice: editUser?.ios_ppv_price,
      ActualPrice: formData?.price,
      convertedPriceRates: formData?.convertedPrices.map(item => ({
        Code: item?.Code,
        Country: item?.country_name || item?.Country,
        RateDifference: item?.difference || item?.RateDifference,
        ConvertedPriceRate: item?.editPrice || item?.convertedPrice,
        ChangedPrice: item?.editPrice || item?.convertedPrice,
        Symbol: item?.Symbol || item?.symbol,
      }))
    };

    let firstApiStatus = false;
    let resultapi = {};
    let resjson = {};

    try {
      const res = await fetch(`${process.env.REACT_APP_Baseurl}/admin/Episode/PayPerViewPrice/StoreUpdate`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(dataToSubmit),
      });

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
      // Optionally show a user-friendly error message
      alert('An error occurred while submitting the form. Please try again.');
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      handleClose();
      setStep(1);
    } else {
      if (resjson?.status === false) {
        props?.setApiresponsealert(resjson);
        props?.setShowalert(true);
      }
    }
  };


  const handleInputChangeeditable = (index, fieldName, value) => {
    const updatedConvertedPrices = [...(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices)];
    updatedConvertedPrices[index][fieldName] = value;
    if (modalType === '1') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    } else {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    }
  };

  const handleCustomPriceTypeChange = async (event) => {
    const selectedCountryCode = event.target.value;

    if (selectedCountryCode && selectedCountryCode !== allCurrencylistfromappichoosed?.code) {
      try {
        // Fetch conversion rates from API
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${selectedCountryCode}`);
        const rates = response.data.rates;

        // Convert and update prices based on the API data
        const convertedPrices = allCurrencylistfromappi?.map(item => {
          let country_name = item?.country;
          let Symbol = item?.Symbol || item?.symbol;
          let Code = item?.code;

          // Find matching country data
          const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
          if (matchingCountry) {
            country_name = matchingCountry?.country;
            Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
          }

          return {
            Code: Code,
            Country: country_name,
            Symbol: Symbol,
            convertedPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            price: formDatacustomprice.price,
            difference: ((formDatacustomprice.price * (rates[item?.code] || 0)) - formDatacustomprice.price).toFixed(2),
            editPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            isEditable: true,
          };
        });

        setFormDatacustomprice(prevState => ({
          ...prevState,
          priceType: selectedCountryCode,
          convertedPrices: convertedPrices
        }));
      } catch (error) {
        console.error('Error fetching currency conversion rates:', error);
      }
    } else {
      // Set the existing data if allCurrencylistfromappichoosed?.code is selected
      const customPrices = allCurrencycustoms?.map(item => ({
        ...item,
        convertedPrice: item?.ChangedPrice || (formDatacustomprice.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
        difference: item?.RateDifference || 0,
        isEditable: true
      }));

      setFormDatacustomprice(prevState => ({
        ...prevState,
        priceType: allCurrencylistfromappichoosed?.code,
        convertedPrices: customPrices
      }));
    }
  };

  const handlePriceChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'global') {
      setFormDataglobalprice(prevState => {
        const updatedState = { ...prevState, [name]: value };
        setDatanewppvpprice(updatedState)
        return updatedState;
      });
    } else if (formType === 'custom') {
      setFormDatacustomprice(prevState => {
        const updatedState = { ...prevState, [name]: value };
        setDatanewppvpprice(updatedState)
        return updatedState;
      });
    }
  };




  // {ppv price with global and customs} 
  // {ppv price with global and customs} 



  const steps = [
    {
      label: "Select campaign settings",
      description: (
        <div className="">
          <div className="pb-2">
            <h4>Episode Upload</h4>
          </div>
          <div className="p-3 iq-card">

            <div>
              <Dropzone accept="video/*" onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone" {...getRootProps()}>
                    <input {...getInputProps({ accept: 'video/*' })} id="add_video_upload" />
                    <p className="draganddrop">Drag and drop video files here, or click to select files</p>
                  </div>
                )}
              </Dropzone>


              <div className="text-center video-overall-progress-li">
                {loading && (
                  <div>
                    <p>Uploading... {overallProgress().toFixed(2)}%</p>
                    <progress value={overallProgress()} max="100" />
                    <div className="upload-progress-list d-flex flex-wrap">
                      {Object.keys(progress)?.map((fileName, index) => (
                        <div key={index} className="upload-progress-item d-flex flex-column align-items-center">
                          <div className="file-info d-flex align-items-center justify-content-center">
                            <div>
                              <p className="file-name mb-0">{fileName.substring(0, 10)}...</p>
                              <p className="file-size mb-0">{fileSizes[fileName]} MB</p>
                            </div>
                          </div>

                          <div className="progress-bar-container">
                            <progress value={progress[fileName]} max="100" className="file-progress-bar" />
                            <span className="progress-percentage ">{progress[fileName]}%</span>
                          </div>

                          {uploadSpeed[fileName] && <span className="file-uploadSpeed">({uploadSpeed[fileName]})</span>}
                          <img
                            onClick={() => cancelUpload(fileName)}
                            src={cancel_video}
                            className="cancel-icon"
                            alt="Cancel"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {uploadedFiles?.length > 0 && !loading && (
                  <div className="uploaded-files-section">
                    <p>Uploaded Files:</p>
                    <ul>
                      {uploadedFiles?.map((file, index) => (
                        <li key={index} className="uploaded-file-item">
                          <span>{file?.name} - {file?.size} MB</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {failedFiles?.length > 0 && (
                  <div className="failed-files-section">
                    <p>Failed Files:</p>
                    <ul>
                      {failedFiles?.map((file, index) => (
                        <li key={index} className="failed-file-item d-flex align-items-center justify-content-between">
                          <span>{file?.name}</span>
                          <img
                            onClick={() => retryUpload(file)}
                            src={retry_video}
                            className="retry-icon"
                            alt="Retry"
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {uploadedFiles?.length > 0 && !loading && (
                  <Button
                    variant="contained"
                    onClick={handleNextNext}
                    className="btn btn-primary proceed-button"
                  >
                    Proceed to Next
                  </Button>
                )}
              </div>


              {/* <div className="text-center video-overall-progress-li">
              {loading && (
                <div>
                  <p>Uploading...{overallProgress().toFixed(2)}%</p>
                  <progress value={overallProgress()} max="100" />
                  {Object.keys(progress).map((fileName, index) => (
                    <div key={index}>

                      <div className="d-flex align-item-center justify-content-center">
                        <div>
                          <p className="d-flex">{fileName} - {progress[fileName]}% </p>
                        </div>
                        <div>
                          {uploadSpeed[fileName] && (
                            <span> ({uploadSpeed[fileName]})</span>
                          )}
                        </div>
                        <div className="ms-2"> <img onClick={() => cancelUpload(fileName)} src={cancel_video} className="w-100" height={15} style={{ cursor: "pointer" }} />
                        </div>
                      </div>


                    </div>
                  ))}
                </div>
              )}

              {uploadedFiles?.length > 0 && !loading && (
                <div className="text-center">
                  <p>Uploaded Files:</p>
                  <ul>
                    {uploadedFiles.map((file, index) => (
                      <li key={index}>
                        {file?.name} - {file?.size} MB
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {failedFiles?.length > 0 && (
                <div className="text-center">
                  <p>Failed Files:</p>
                  <ul>
                    {failedFiles?.map((file, index) => (
                      <li key={index}>
                        <div className="d-flex align-item-center justify-content-center">
                          <div>
                            <p className="d-flex"> {file?.name} </p>
                          </div>
                          <div className="ms-2"> <img onClick={() => retryUpload(file)} src={retry_video} className="w-100" height={15} style={{ cursor: "pointer" }} />

                          </div>
                        </div>

                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {uploadedFiles.length > 0 && !loading && (
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNextNext();
                  }}
                  className="btn btn-primary"
                >
                  Proceed to Next
                </Button>
              )}
            </div> */}



            </div>

            {/* LIBRARY DATA ALL  */}
            {/* LIBRARY DATA ALL  */}
            <div className="mt-3">
              <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdroplibrarylibrary" >  Choose from Library</button>
            </div>

            <div className="modal fade" id="staticBackdroplibrarylibrary" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">All Library Lists</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    {/* video modal */}
                    <div
                      className={`modal packageModel ${modelClose ? "show" : ""}`}
                      tabIndex="-1"
                      style={{ display: `${modelClose ? "block" : "none"}` }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Confirm Selected</h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={closeModal}
                            ></button>
                          </div>
                          <div className="modal-body">
                            {itemToDeleteVideo?.length === 1 ? (
                              <p> Are you sure you want to Add this item ?</p>
                            ) : (
                              <p className="fs-6">
                                Are you sure you want to Add Selected the items ?
                              </p>
                            )}
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={closeModal}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleConfirmDeletevideo}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* live modal */}
                    <div
                      className={`modal  packageModel ${modalCloseLive ? "show" : ""}`}
                      tabIndex="-1"
                      style={{ display: modalCloseLive ? "block" : "none" }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Confirm Selected</h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={closeLiveModal}
                            ></button>
                          </div>
                          <div className="modal-body">
                            {itemToDeleteLive?.length === 1 ? (
                              <p> Are you sure you want to Add this item ?</p>
                            ) : (
                              <p className="fs-6">
                                Are you sure you want to Add Selected the items ?
                              </p>
                            )}
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={closeLiveModal}
                            >
                              Close
                            </button>

                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleDeleteLiveData}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* audio modal */}
                    <div
                      className={`modal  packageModel ${modalCloseAudio ? "show" : ""}`}
                      tabIndex="-1"
                      style={{ display: modalCloseAudio ? "block" : "none" }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Confirm Selected</h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={closeAudioModal}
                            ></button>
                          </div>
                          <div className="modal-body">
                            {itemToDeleteAudio?.length === 1 ? (
                              <p> Are you sure you want to Add this item ?</p>
                            ) : (
                              <p className="fs-6">
                                Are you sure you want to Add Selected the items ?
                              </p>
                            )}
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={closeAudioModal}
                            >
                              Close
                            </button>

                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleDeleteAudioData}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* episode modal */}
                    <div
                      className={`modal  packageModel ${modalCloseEpisode ? "show" : ""}`}
                      tabIndex="-1"
                      style={{ display: modalCloseEpisode ? "block" : "none" }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Confirm Selected</h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={closeEpisodeModal}
                            ></button>
                          </div>
                          <div className="modal-body">
                            {itemToDeleteEpisode?.length === 1 ? (
                              <p> Are you sure you want to Add this item ?</p>
                            ) : (
                              <p className="fs-6">
                                Are you sure you want to Add Selected the items ?
                              </p>
                            )}
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={closeEpisodeModal}
                            >
                              Close
                            </button>

                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleDeleteEpisodeData}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <section className="section Master container-fluid">
                      <div className="row">
                        <div className="col-sm-12">

                          <div className="iq-card-body table-responsive ">
                            <div className="table-view">
                              <ul
                                className="nav nav-pills justify-content-center row"
                                id="pills-tab "
                                role="tablist"
                              >
                                <li
                                  className="nav-item col-12 col-sm-6 col-md-3 "
                                  role="presentation"
                                >
                                  <button
                                    className="nav-link active fs-6 fw-bold px-4 w-100  mx-auto "
                                    id="pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                  >
                                    Videos
                                  </button>
                                </li>
                                {/* <li
                                    className="nav-item col-12 col-sm-6 col-md-3 "
                                    role="presentation"
                                  >
                                    <button
                                      className="nav-link fs-6 fw-bold px-4 w-100  mx-auto"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Live Stream Videos
                                    </button>
                                  </li> */}
                                <li
                                  className="nav-item col-6 col-sm-6 col-md-3 "
                                  role="presentation"
                                >
                                  <button
                                    className="nav-link fs-6 fw-bold px-4 w-100  mx-auto"
                                    id="pills-episode-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-episode"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-contact"
                                    aria-selected="false"
                                  >
                                    Episode
                                  </button>
                                </li>
                                <li
                                  className="nav-item col-6 col-sm-6 col-md-3 "
                                  role="presentation"
                                >
                                  <button
                                    className="nav-link fs-6 fw-bold px-4 w-100  mx-auto"
                                    id="pills-contact-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-contact"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-contact"
                                    aria-selected="false"
                                  >
                                    Audios
                                  </button>
                                </li>

                              </ul>
                              <hr></hr>
                              <div className="tab-content p-0" id="pills-tabContent">
                                <div
                                  className="tab-pane fade show active"
                                  id="pills-home"
                                  role="tabpanel"
                                  aria-labelledby="pills-home-tab"
                                >
                                  <div className=" m-0 p-0">
                                    <div className="container-fluid">
                                      <section className="section all-video-stream ">
                                        <div className="col-lg-12 ">
                                          <div className="iq-card shadow-none p-0">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                                              <div className="filterContainer">
                                                <input
                                                  type="text"
                                                  placeholder="Filter all.."
                                                  className="filter form-control "
                                                  value={filterVideo}
                                                  onChange={handleFilterChange}
                                                />
                                                {filterVideo?.length > 0 && (
                                                  <button
                                                    type="button"
                                                    className="closeBtn"
                                                    onClick={handleClear}
                                                  >
                                                    <i className="fa fa-times"></i>
                                                  </button>
                                                )}
                                              </div>
                                              <div className="btn-group flex-wrap justify-content-between justify-content-sm-end ">
                                                {itemToDeleteVideo?.length > 0 && (
                                                  <button
                                                    className="btn btn-primary d-flex d-inline-flex  fw-bolder  dropdown-toggle mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                                                    onClick={openModal}
                                                  >
                                                    Move Library to Episode
                                                  </button>
                                                )}
                                                {/* <div
                                                  className="dropdown d-block mb-2 mb-sm-0 mx-0 "
                                                  key={"2"}
                                                >
                                                  <button
                                                    className="btn btn-success dropdown-toggle d-flex mx-0"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    <span className="fw-bolder">Export </span>
                                                    <img
                                                      src={ExportIcon}
                                                      className="ms-2 exportIcon"
                                                      alt="exportIcon"
                                                    />
                                                  </button>
                                                  <ul
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton1"
                                                  >
                                                    <li className="dropdown-item">
                                                      <CSVLink
                                                        headers={exportHeaders}
                                                        data={filteredVideoData}
                                                        className="text-dark d-block"
                                                        filename="VIDEO-LIST.csv"
                                                      >
                                                        CSV
                                                      </CSVLink>
                                                    </li>
                                                    <li
                                                      className="dropdown-item"
                                                      onClick={() => {
                                                        conditionalGeneratePDF(0);
                                                      }}
                                                    >
                                                      PDF
                                                    </li>
                                                    <li
                                                      className="dropdown-item"
                                                      onClick={() => {
                                                        generateXLSX(0);
                                                      }}
                                                    >
                                                      XLSX
                                                    </li>
                                                  </ul>
                                                </div> */}

                                                {/* <div className="dropdown d-block mx-0">
                                                  <button
                                                    className="btn btn-success dropdown-toggle d-flex ms-2"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    <span className="fw-bolder">
                                                      Export All
                                                    </span>
                                                    <img
                                                      src={ExportIcon}
                                                      className="ms-2 exportIcon"
                                                      alt="exportAllIcon"
                                                    />
                                                  </button>
                                                  <ul
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton1"
                                                  >
                                                    <li
                                                      className="dropdown-item"
                                                      onClick={() => setPage(0)}
                                                    >
                                                      <CSVLink
                                                        headers={exportHeaders}
                                                        data={videoData}
                                                        className="text-dark d-block"
                                                        filename="VIDEO-LIST.csv"
                                                      >
                                                        CSV
                                                      </CSVLink>
                                                    </li>
                                                    <li
                                                      className="dropdown-item"
                                                      onClick={() => {
                                                        conditionalGeneratePDF(1);
                                                      }}
                                                    >
                                                      PDF
                                                    </li>
                                                    <li
                                                      className="dropdown-item"
                                                      onClick={() => {
                                                        generateXLSX(1);
                                                      }}
                                                    >
                                                      XLSX
                                                    </li>
                                                  </ul>
                                                </div> */}
                                              </div>
                                            </div>
                                            <div className="bootstrapTable">
                                              <BootstrapTable
                                                keyField="id"
                                                columns={columns}
                                                data={filteredVideoData}
                                                pagination={commonPaginationConfig(
                                                  videoSizePerPageList
                                                )}
                                                className="no-border-table"
                                                noDataIndication={
                                                  <div className="noRecord">
                                                    {loading ? (
                                                      <label>
                                                        {" "}
                                                        <Response_Loading_processing></Response_Loading_processing>
                                                        Loading...
                                                      </label>
                                                    ) : (
                                                      filteredVideoData?.length <= 0 && (
                                                        <p>No Video Found!</p>
                                                      )
                                                    )}
                                                  </div>
                                                }
                                                hover
                                                headerClasses="videoWidth"
                                                selectRow={selectRow}
                                                selectAll={selectAllVideo}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                    </div>
                                  </div>
                                </div>

                                {/* <div
                                  className="tab-pane fade"
                                  id="pills-profile"
                                  role="tabpanel"
                                  aria-labelledby="pills-profile-tab"
                                >
                                  <section className="section container-fluid ">
                                    <div id="content-page" className="content-page">
                                      <div className="iq-card  shadow-none p-0">
                                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                                          <div className="filterContainer">
                                            <input
                                              type="text"
                                              placeholder="Filter all.."
                                              className="filter form-control "
                                              value={filterLive}
                                              onChange={handleFilterChangeLive}
                                            />
                                            {filterLive?.length > 0 && (
                                              <button
                                                type="button"
                                                className="closeBtn"
                                                onClick={handleClearLive}
                                              >
                                                <i className="fa fa-times"></i>
                                              </button>
                                            )}
                                          </div>
                                          <div className="btn-group flex-wrap justify-content-between justify-content-sm-end">
                                            {itemToDeleteLive?.length > 0 && (
                                              <button
                                                className="btn btn-danger d-inline-flex  fw-bolder dropdown-toggle  mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                                                onClick={openModalMultiDeleteLive}
                                              >
                                                <MdDelete className="exportIcon " />
                                                Delete
                                              </button>
                                            )}
                                            <div
                                              className="dropdown d-block  mb-2 mb-sm-0 mx-0 "
                                              key={"2"}
                                            >
                                              <button
                                                className="btn btn-success dropdown-toggle d-flex mx-0"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <span className="fw-bolder">Export </span>
                                                <img
                                                  src={ExportIcon}
                                                  alt="image_all"
                                                  className="ms-2 exportIcon"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                <li className="dropdown-item">
                                                  <CSVLink
                                                    headers={exportHeadersLive}
                                                    data={filteredLiveData}
                                                    className="text-dark d-block"
                                                    filename="LIVE-FILTERED-LIST.csv"
                                                  >
                                                    CSV
                                                  </CSVLink>
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    conditionalGeneratePDFLive(0);
                                                  }}
                                                >
                                                  PDF
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    generateXLSXLIVE(0);
                                                  }}
                                                >
                                                  XLSX
                                                </li>
                                              </ul>
                                            </div>

                                            <div className="dropdown d-block ">
                                              <button
                                                className="btn btn-success dropdown-toggle d-flex ms-2"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <span className="fw-bolder">Export All</span>
                                                <img
                                                  src={ExportIcon}
                                                  alt="image_all"
                                                  className="ms-2 exportIcon"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                <li className="dropdown-item">
                                                  <CSVLink
                                                    headers={exportHeadersLive}
                                                    data={liveData}
                                                    className="text-dark d-block"
                                                    filename="ALL-LIVES-LIST.csv"
                                                  >
                                                    CSV
                                                  </CSVLink>
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    conditionalGeneratePDFLive(1);
                                                  }}
                                                >
                                                  PDF
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    generateXLSXLIVE(1);
                                                  }}
                                                >
                                                  XLSX
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="bootstrapTable">
                                          <BootstrapTable
                                            keyField="id"
                                            columns={liveColumns}
                                            data={filteredLiveData}
                                            pagination={commonPaginationConfig(
                                              liveSizePerPageList
                                            )}
                                            className="no-border-table"
                                            noDataIndication={
                                              <div className="noRecord">
                                                {loading ? (
                                                  <label>
                                                    {" "}
                                                    <Response_Loading_processing></Response_Loading_processing>
                                                    Loading...
                                                  </label>
                                                ) : (
                                                  filteredLiveData?.length <= 0 && (
                                                    <p>No Live Streams Available. !!!</p>
                                                  )
                                                )}
                                              </div>
                                            }
                                            hover
                                            headerClasses="videoWidth"
                                            selectRow={selectRowLive}
                                            selectAll={selectAllLive}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                </div> */}
                                <div
                                  className="tab-pane fade"
                                  id="pills-contact"
                                  role="tabpanel"
                                  aria-labelledby="pills-contact-tab"
                                >
                                  <section className="section container-fluid ">
                                    <div id="content-page" className="content-page">
                                      <div className="iq-card shadow-none p-0">
                                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                                          <div className="filterContainer">
                                            <input
                                              type="text"
                                              placeholder="Filter all.."
                                              className="filter form-control "
                                              value={filterAudio}
                                              onChange={handleFilterChangeAudio}
                                            />
                                            {filterAudio?.length > 0 && (
                                              <button
                                                type="button"
                                                className="closeBtn"
                                                onClick={handleClearAudio}
                                              >
                                                <i className="fa fa-times"></i>
                                              </button>
                                            )}
                                          </div>
                                          <div className="btn-group flex-wrap justify-content-between justify-content-sm-end">
                                            {/* {itemToDeleteAudio?.length > 0 && (
                                              <button
                                                className="btn btn-primary d-inline-flex  fw-bolder dropdown-toggle mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                                                onClick={openModalMultiDeleteAudio}
                                              >
                                                <MdDelete className="exportIcon " />
                                                Save Library
                                              </button>
                                            )} */}
                                            {/* <div
                                              className="dropdown d-block mb-2 mb-sm-0 mx-0  "
                                              key={"2"}
                                            >
                                              <button
                                                className="btn btn-success dropdown-toggle d-flex mx-0"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <span className="fw-bolder">Export </span>
                                                <img
                                                  src={ExportIcon}
                                                  alt="image_all"
                                                  className="ms-2 exportIcon"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                <li className="dropdown-item">
                                                  <CSVLink
                                                    headers={exportHeadersAudio}
                                                    data={filteredAudioData}
                                                    className="text-dark d-block"
                                                    filename="AUDIO-FILTERED-LIST.csv"
                                                  >
                                                    CSV
                                                  </CSVLink>
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    conditionalGeneratePDFAudio(0);
                                                  }}
                                                >
                                                  PDF
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    generateXLSXAUDIO(0);
                                                  }}
                                                >
                                                  XLSX
                                                </li>
                                              </ul>
                                            </div> */}

                                            {/* <div className="dropdown d-block ">
                                              <button
                                                className="btn btn-success dropdown-toggle d-flex ms-2"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <span className="fw-bolder">Export All</span>
                                                <img
                                                  src={ExportIcon}
                                                  alt="image_all"
                                                  className="ms-2 exportIcon"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                <li className="dropdown-item">
                                                  <CSVLink
                                                    headers={exportHeadersAudio}
                                                    data={audioData}
                                                    className="text-dark d-block"
                                                    filename="ALL-AUDIO-LIST.csv"
                                                  >
                                                    CSV
                                                  </CSVLink>
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    conditionalGeneratePDFAudio(1);
                                                  }}
                                                >
                                                  PDF
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    generateXLSXAUDIO(1);
                                                  }}
                                                >
                                                  XLSX
                                                </li>
                                              </ul>
                                            </div> */}
                                          </div>
                                        </div>
                                        <div className="bootstrapTable">
                                          <BootstrapTable
                                            keyField="id"
                                            columns={columnsAudio}
                                            data={filteredAudioData}
                                            pagination={commonPaginationConfig(
                                              audioSizePerPageList
                                            )}
                                            className="no-border-table"
                                            noDataIndication={
                                              <div className="noRecord">
                                                {loading ? (
                                                  <label>
                                                    {" "}
                                                    <Response_Loading_processing></Response_Loading_processing>
                                                    Loading...
                                                  </label>
                                                ) : (
                                                  filteredAudioData?.length <= 0 && (
                                                    <p>No Audios Available !!!</p>
                                                  )
                                                )}
                                              </div>
                                            }
                                            hover
                                            headerClasses="videoWidth"
                                            selectRow={selectRowAudio}
                                            selectAll={selectAllAudio}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="pills-episode"
                                  role="tabpanel"
                                  aria-labelledby="pills-episode-tab"
                                >
                                  <section className="section container-fluid">
                                    <div id="content-page" className="content-page">
                                      <div className="iq-card shadow-none p-0">
                                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                                          <div className="filterContainer">
                                            <input
                                              type="text"
                                              placeholder="Filter all.."
                                              className="filter form-control "
                                              value={filterEpisode}
                                              onChange={handleFilterChangeEpisode}
                                            />
                                            {filterEpisode?.length > 0 && (
                                              <button
                                                type="button"
                                                className="closeBtn"
                                                onClick={handleClearEpisode}
                                              >
                                                <i className="fa fa-times"></i>
                                              </button>
                                            )}
                                          </div>
                                          <div className="btn-group flex-wrap justify-content-between justify-content-sm-end">
                                            {/* {itemToDeleteEpisode?.length > 0 && (
                                              <button
                                                className="btn btn-primary d-inline-flex  fw-bolder dropdown-toggle mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                                                onClick={openModalMultiDeleteEpisode}
                                              >
                                                <MdDelete className="exportIcon " />
                                                Save Library
                                              </button>
                                            )} */}
                                            {/* <div
                                              className="dropdown d-block mb-2 mb-sm-0 mx-0 "
                                              key={"2"}
                                            >
                                              <button
                                                className="btn btn-success dropdown-toggle d-flex mx-0 "
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <span className="fw-bolder">Export </span>
                                                <img
                                                  src={ExportIcon}
                                                  alt="image_all"
                                                  className="ms-2 exportIcon"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                <li className="dropdown-item">
                                                  <CSVLink
                                                    headers={exportHeadersEpisode}
                                                    data={filteredEpisodeData}
                                                    className="text-dark d-block"
                                                    filename="FILTERED-EPISODE-LIST.csv"
                                                  >
                                                    CSV
                                                  </CSVLink>
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    conditionalGeneratePDFEpisode(0);
                                                  }}
                                                >
                                                  PDF
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    generateXLSXEPISODE(0);
                                                  }}
                                                >
                                                  XLSX
                                                </li>
                                              </ul>
                                            </div> */}

                                            {/* <div className="dropdown d-block ">
                                              <button
                                                className="btn btn-success dropdown-toggle d-flex ms-2"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <span className="fw-bolder">Export All</span>
                                                <img
                                                  src={ExportIcon}
                                                  alt="image_all"
                                                  className="ms-2 exportIcon"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                <li className="dropdown-item">
                                                  <CSVLink
                                                    headers={exportHeadersEpisode}
                                                    data={episodeData}
                                                    className="text-dark d-block"
                                                    filename="ALL-EPISODE-LIST.csv"
                                                  >
                                                    CSV
                                                  </CSVLink>
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    conditionalGeneratePDFEpisode(1);
                                                  }}
                                                >
                                                  PDF
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    generateXLSXEPISODE(1);
                                                  }}
                                                >
                                                  XLSX
                                                </li>
                                              </ul>
                                            </div> */}
                                          </div>
                                        </div>
                                        <div className="bootstrapTable">
                                          <BootstrapTable
                                            keyField="id"
                                            columns={columnsEpisode}
                                            data={filteredEpisodeData}
                                            pagination={commonPaginationConfig(
                                              episodeSizePerPageList
                                            )}
                                            className="no-border-table"
                                            noDataIndication={
                                              <div className="noRecord">
                                                {loading ? (
                                                  <label>
                                                    {" "}
                                                    <Response_Loading_processing></Response_Loading_processing>
                                                    Loading...
                                                  </label>
                                                ) : (
                                                  filteredEpisodeData?.length <= 0 && (
                                                    <p>No Episode Available !!!</p>
                                                  )
                                                )}
                                              </div>
                                            }
                                            hover
                                            headerClasses="videoWidth"
                                            selectRow={selectRowEpisode}
                                            selectAll={selectAllEpisode}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  {/* <div className="modal-footer">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      {itemToDeleteVideo?.length > 0 && (
                        <button
                          className="btn btn-primary d-flex d-inline-flex  fw-bolder  dropdown-toggle mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                          onClick={openModalvideo}
                        >
                          Save Video Library
                        </button>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {/* LIBRARY DATA ALL  */}
            {/* LIBRARY DATA ALL  */}

          </div>


          <div
            className="modal fade"
            id="exampleModallive"
            tabindex="-1"
            aria-labelledby="exampleModalLabellive"
            aria-hidden="true"
          >
            <div className="modal-dialog delete_confirm">
              <div className="modal-content text-center">
                <div className="modal-body">
                  {isConfirmationOpen && (
                    <div>
                      <h5 className="modal-title p-3" id="exampleModalLabellive">
                        <p className="fs-6">
                          Are you sure you want to delete the selected items?
                        </p>
                      </h5>
                      <div className="col-lg-12 row p-4">
                        <div className="col-lg-6">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              handleConfirmDelete();
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                        <div className="col-lg-6">
                          <button
                            className="btn btn-primary"
                            onClick={handleCancelDelete}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {isSuccessMessageOpen && (
                    <div className="success-message">
                      <div className="p-5">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </div>
                      <p className="fs-6 fw-bold">
                        Selected items have been successfully deleted.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="banner-slider">
            {showModalmodal && (
              <div
                className="modal"
                tabIndex="-1"
                role="dialog"
                style={{ display: "block" }}
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Slider </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={closeModalslider}
                      ></button>
                    </div>
                    <div className="modal-body">
                      Are you sure you want to Switch the Slider status?
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={closeModalslider}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={confirmToggle}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className={`modal ${itemToDelete !== null ? "show" : ""}`}
            tabIndex="-1"
            style={{ display: itemToDelete !== null ? "block" : "none" }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Deletion</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}
                  ></button>
                </div>
                <div className="modal-body">
                  {successMessage ? (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  ) : (
                    <p>Are you sure you want to delete this Episode?</p>
                  )}
                </div>
                <div className="modal-footer">
                  {successMessage ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleDeletedata}
                      >
                        Yes
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="panel-body mt-3">
              <div id="nestable" className="nested-list dd with-margins">
                <div className="text-end p-2">
                  {selectedItems?.length > 0 && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleDeletemulti}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModallive"
                    >
                      Delete Selected
                    </button>
                  )}
                </div>

                <div className="col-lg-12 ">
                  <div className="card pt-3">


                    {/* episode modal */}
                    <div
                      className={`modal  packageModel ${modalCloseEpisodeepisode ? "show" : ""}`}
                      tabIndex="-1"
                      style={{ display: modalCloseEpisodeepisode ? "block" : "none" }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Confirm Deletion</h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={closeEpisodeepisodeModal}
                            ></button>
                          </div>
                          <div className="modal-body">
                            {itemToDeleteEpisodeepisode?.length === 1 ? (
                              <p className="fs-6">
                                {" "}
                                Are you sure you want to delete this Episode ?
                              </p>
                            ) : (
                              <p className="fs-6">
                                Are you sure you want to delete the selected Episode ?
                              </p>
                            )}
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={closeEpisodeepisodeModal}
                            >
                              Close
                            </button>

                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={handleDeleteEpisodeepisodeData}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>


                    {/* end epidoSEESSS */}

                    <section className="section container-fluid">
                      <div>
                        {showOverlay && <Response_Processing></Response_Processing>}
                      </div>
                      <div id="content-page" className="content-page">
                        <div className="iq-card shadow-none p-0">
                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                            <div className="filterContainer">
                              <input
                                type="text"
                                placeholder="Filter all.."
                                className="filter form-control "
                                value={filterEpisodeepisode}
                                onChange={handleFilterChangeEpisodeepisode}
                              />
                              {filterEpisodeepisode?.length > 0 && (
                                <button
                                  type="button"
                                  className="closeBtn"
                                  onClick={handleClearEpisodeepisode}
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              )}
                            </div>
                            <div className="btn-group flex-wrap justify-content-between justify-content-sm-end">
                              {itemToDeleteEpisodeepisode?.length > 0 && (
                                <button
                                  className="btn btn-danger d-inline-flex  fw-bolder dropdown-toggle mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                                  onClick={openModalMultiDeleteEpisodeepisode}
                                >
                                  <MdDelete className="exportIcon " />
                                  Delete
                                </button>
                              )}
                              <div
                                className="dropdown d-block mb-2 mb-sm-0 mx-0 "
                                key={"2"}
                              >
                                <button
                                  className="btn btn-success dropdown-toggle d-flex mx-0 "
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span className="fw-bolder">Export </span>
                                  <img
                                    src={ExportIcon}
                                    alt="image_all"
                                    className="ms-2 exportIcon"
                                  />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li className="dropdown-item">
                                    <CSVLink
                                      headers={exportHeadersEpisodeepisode}
                                      data={filteredEpisodeepisodeData}
                                      className="text-dark d-block"
                                      filename="FILTERED-EPISODE-LIST.csv"
                                    >
                                      CSV
                                    </CSVLink>
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      conditionalGeneratePDFEpisodeepisode(0);
                                    }}
                                  >
                                    PDF
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      generateXLSXEPISODEepisode(0);
                                    }}
                                  >
                                    XLSX
                                  </li>
                                </ul>
                              </div>

                              <div className="dropdown d-block ms-2">
                                <button
                                  className="btn btn-success dropdown-toggle d-flex"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span className="fw-bolder">Export All</span>
                                  <img
                                    src={ExportIcon}
                                    alt="image_all"
                                    className="ms-2 exportIcon"
                                  />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li className="dropdown-item">
                                    <CSVLink
                                      headers={exportHeadersEpisodeepisode}
                                      data={episodeepisodeData}
                                      className="text-dark d-block"
                                      filename="ALL-EPISODE-LIST.csv"
                                    >
                                      CSV
                                    </CSVLink>
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      conditionalGeneratePDFEpisodeepisode(1);
                                    }}
                                  >
                                    PDF
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      generateXLSXEPISODEepisode(1);
                                    }}
                                  >
                                    XLSX
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="bootstrapTable episodedetailsorder">
                            {/* <BootstrapTable
                              keyField="id"
                              columns={columnsEpisodeepisode}
                              data={filteredEpisodeepisodeData}
                              pagination={commonPaginationConfigepisode(
                                episodeepisodeSizePerPageList
                              )}
                              className="no-border-table"
                              noDataIndication={
                                <div className="noRecord">
                                  {loading ? (
                                    <label>
                                      {" "}
                                      <Response_Loading_processing></Response_Loading_processing>
                                      Loading...
                                    </label>
                                  ) : (
                                    filteredEpisodeepisodeData?.length <= 0 && (
                                      <p>No Episode Available !!!</p>
                                    )
                                  )}
                                </div>
                              }
                              hover
                              headerClasses="videoWidth"
                              selectRow={selectRowEpisodeepisode}
                              selectAll={selectAllEpisodeepisode}
                            /> */}
                            <div className="text-center px-3">
                              <div className="d-flex align-items-center pb-2 justify-content-between orderContainer theme-border-secondary-color border-1">
                                <div className="text-start"> <input type="checkbox" onChange={(e) => multiSelect(e, "all")} checked={itemToDeleteEpisodeepisode?.length === filteredEpisodeepisodeData?.length} /> </div>
                                <div className="orderHeading">Episodes</div>
                                <div className="orderHeading">Uploaded by</div>
                                <div className="orderHeading">Status</div>
                                <div className="orderHeading">Slider</div>
                                <div className="orderHeading text-start">Actions</div>
                              </div>
                              <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="menu-items">
                                  {(provided) => (
                                    <ul
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {filteredEpisodeepisodeData?.map((menuItem, index) => (
                                        <Draggable key={menuItem?.id.toString()} draggableId={menuItem?.id.toString()} index={index} >
                                          {(provided) => (
                                            <li ref={provided.innerRef}  {...provided.draggableProps} {...provided.dragHandleProps}>
                                              <div className="d-flex align-items-center justify-content-between orderContainer py-3 theme-border-secondary-color border-1">
                                                <div className="text-start"><input type="checkbox" value={menuItem?.id} key={menuItem.id} onChange={(e) => multiSelect(e, "single")} checked={itemToDeleteEpisodeepisode?.includes(menuItem?.id)} /></div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                  {menuItem?.image_url || menuItem?.Thumbnail ? <img key={menuItem?.image_url} src={menuItem.image_url ? menuItem.image_url : menuItem.Thumbnail} alt={menuItem?.title ? menuItem?.title : menuItem?.title} className="profile theme-bg-color-secondary " /> : <div className="profile theme-bg-color-secondary text-dark d-flex align-items-center justify-content-center admin-input-title">{menuItem?.title?.slice(0, 1).toUpperCase()}</div>}
                                                  <div className="rightVideoTitle text-start">
                                                    <Link className="theme-text-color table-heading d-flex  align-items-center" target="_blank" to={`${process.env.REACT_APP_Baseurl_Frontend}/lang/series/${seriesParent?.slug}/${menuItem.slug}`}> <span>{`${menuItem.title ? menuItem?.title?.slice(0, 15) : ' '}...`} </span><CiShare1 style={{ width: "15px", height: "15px", strokeWidth: '1px', marginLeft: "6px" }} /> </Link>
                                                    {menuItem?.duration != "0" ? <span className="me-1  dateText theme-text-color table-subheading"> {menuItem?.duration}</span> : ""}
                                                    <span className="dateText table-subheading theme-text-color">{menuItem?.upload_on}</span>
                                                  </div>
                                                </div>

                                                <div>{menuItem?.uploaded_by}</div>
                                                <div className={`d-inline-flex justify-content-center px-3 py-1 text-center ${menuItem?.active === "PUBLISHED" ? "PUBLISHED_active" : menuItem?.active === "SCHEDULED" ? " DISABLED_active" : "UNPUBLISHED_active"}`} > {menuItem?.active} </div>
                                                <div>
                                                  <label className="switch">
                                                    <input
                                                      name="banner"
                                                      id="banner"
                                                      className={menuItem?.banner === 0 ? "inactive" : null}
                                                      checked={menuItem?.banner === 1}
                                                      onChange={() => handleToggle(menuItem)}
                                                      onClick={() => handleToggle(menuItem)}
                                                      type="checkbox"
                                                    />
                                                    <span className="slider round"></span>
                                                  </label>
                                                </div>

                                                <div className="editdropdown text-start">
                                                  <span className="editdropdown-button">
                                                    <span>
                                                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                    </span>
                                                  </span>
                                                  <div className="editdropdown-menu">
                                                    <Link to={`/edit-episode/manage/${menuItem?.id}`}>
                                                      <span>
                                                        <img src={edititem} alt="flogo" width={20} height={20} />
                                                        <span className="ms-2"> Edit Episode </span>
                                                      </span>
                                                    </Link>

                                                    <div
                                                      onClick={() => openModalSingleDeleteEpisodeepisode(menuItem?.id)}
                                                      className="commonActionPadding"
                                                    >
                                                      <img src={deleteitem} alt="flogo" width={20} height={20} />
                                                      <span className="ms-2"> Delete Episode</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                            </li>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </ul>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>



                    {/* end epidoSEESSS */}


                    {/* {loading ? (
                      <div className="p-2 text-start iq-card-body">
                        <Table>
                          <thead className="p-1">
                            <tr className="title">
                              <th>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                  />
                                </label>
                              </th>
                              <th>
                                <label>EPISODE</label>
                              </th>
                              <th>
                                <label>EPISODE NAME</label>
                              </th>
                              <th>
                                <label>SLIDER</label>
                              </th>
                              <th>
                                <label>STATUS</label>
                              </th>
                              <th>
                                <label>ACTION</label>
                              </th>
                            </tr>
                          </thead>
                        </Table>

                        <div className="text-center">
                          <label>
                            {" "}
                            <Response_Loading_processing></Response_Loading_processing>{" "}
                            Loading...
                          </label>
                        </div>




                        <div className="row align-items-center text-center d-flex">
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary "
                              onClick={handlePreClick}
                              disabled={page <= 1}
                            >
                              {"<< Previous"}
                            </button>
                          </div>
                          <div className="col-lg-4">
                            <p className="">{page}</p>
                          </div>
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleNxtClick}
                              disabled={data?.length < itemsPerPage}
                            >
                              {"Next >>"}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : videodata.length > 0 ? (
                      <div className="p-2 text-start iq-card-body">
                        <Table>
                          <thead className="p-1">
                            <tr className="title">
                              <th>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                  />
                                </label>
                              </th>
                              <th>
                                <label>EPISODES</label>
                              </th>
                              <th>
                                <label>SLIDER</label>
                              </th>
                              <th>
                                <label>STATUS</label>
                              </th>
                              <th>
                                <label>ACTION</label>
                              </th>
                            </tr>
                          </thead>
                          {currentItems?.map((item, key) => (
                            <tbody>
                              <tr key={item?.id}>
                                <td key={item?.id}>
                                  <input
                                    type="checkbox"
                                    checked={selectedItems.includes(item?.id)}
                                    onChange={() => handleMultiSelect(item?.id)}
                                  />
                                </td>
                                <td className="">
                                  <div className="d-flex text-start align-items-center ">
                                    <div>
                                      {loading ? (
                                        <img
                                          src={item?.Thumbnail}
                                          className="w-100"
                                          height="100"
                                        />
                                      ) : (
                                        <img
                                          src={item?.Thumbnail}
                                          alt="Image"
                                          width="50"
                                        />
                                      )}
                                    </div>
                                    <div>
                                      <div className="ms-2">
                                        <div>{item?.title}</div>
                                        <div className="d-flex ">
                                          <div className="text-start">
                                            {item?.duration}
                                          </div>
                                          <div className="ms-2">
                                            <span>{item?.upload_on}</span>
                                          </div>
                                        </div>
                                        <div>
                                          {item?.slug !== null ? (
                                            <div className="text-start">
                                              <label className="m-0">
                                                <div className="tooltip-container">
                                                  <p className="iq-bg-warning">
                                                    <Link
                                                      to={`${process.env.REACT_APP_Baseurl_Frontend}/videos/${item?.slug}`}
                                                    >
                                                      <i
                                                        className="fa fa-share-alt"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </Link>
                                                    <span className="tooltip-text">
                                                      View
                                                    </span>
                                                  </p>
                                                </div>
                                              </label>
                                            </div>
                                          ) : (
                                            <>
                                              <div className="text-start">
                                                <label className="m-0">
                                                  <div className="tooltip-container">
                                                    <p className="iq-bg-warning">
                                                      <i
                                                        className="fa fa-share-alt"
                                                        aria-hidden="true"
                                                        disabled
                                                      ></i>
                                                      <span className="tooltip-text">
                                                        Diable View
                                                      </span>
                                                    </p>
                                                  </div>
                                                </label>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {loading ? (
                                    <img
                                      src={item?.Thumbnail}
                                      className="w-100"
                                      height="100"
                                    />
                                  ) : (
                                    <img
                                      src={item?.Thumbnail}
                                      // alt="Image"
                                      className="w-100"
                                      height="100"
                                    />
                                  )}
                                </td>
                                <td className="edit_genre">
                                  <p>{item?.title}</p>
                                </td>
                                <td>
                                  <div key={key}>
                                    <label className="switch">
                                      <input
                                        name="banner"
                                        id="banner"
                                        className={
                                          item?.banner === 0 ? "inactive" : null
                                        }
                                        checked={item?.banner === 1}
                                        onChange={() => handleToggle(item)}
                                        onClick={() => handleToggle(item)}
                                        type="checkbox"
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                </td>
                                <td className="text-center">
                                  {item?.active == 0 || item?.active == null ? (
                                    <td className="UNPUBLISHED_active">
                                      UNPUBLISHED
                                    </td>
                                  ) : item?.active == 1 &&
                                    item?.active == 1 &&
                                    item?.active == 1 ? (
                                    <td className="PUBLISHED_active">
                                      PUBLISHED
                                    </td>
                                  ) : (
                                    <td className="UNPUBLISHED_active">
                                      DRAFT
                                    </td>
                                  )}
                                </td>
                                <td>
                                  <div className="editdropdown">
                                    <span className="editdropdown-button">
                                      <span>
                                        <i
                                          className="fa fa-ellipsis-v"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </span>
                                    <div className="editdropdown-menu text-start">
                                      <Link
                                        to={
                                          "/edit-episode/video-manage/" +
                                          item?.id
                                        }
                                        className="edit"
                                      >
                                        <span>
                                          <img
                                            src={edititem}
                                            alt="flogo"
                                            width={20}
                                            height={20}
                                          />
                                          <span className="ms-2">
                                            Edit Video
                                          </span>
                                        </span>
                                      </Link>

                                      <Link
                                        to={
                                          "/edit-episode/manage/" +
                                          item?.id
                                        }
                                        className="edit"
                                      >
                                        <span>
                                          <img
                                            src={edititem}
                                            alt="flogo"
                                            width={20}
                                            height={20}
                                          />
                                          <span className="ms-2">
                                            Edit Meta
                                          </span>
                                        </span>
                                      </Link>
                                      <Link to="" className="delete">
                                        <span
                                          onClick={() => openModal(item?.id)}
                                        >
                                          <img
                                            src={deleteitem}
                                            alt="flogo"
                                            width={20}
                                            height={20}
                                          />
                                          <span className="ms-2">
                                            Delete Video
                                          </span>
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </Table>

                        <div className="row align-items-center text-center d-flex">
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary "
                              onClick={handlePreClick}
                              disabled={page <= 1}
                            >
                              {"<< Previous"}
                            </button>
                          </div>
                          <div className="col-lg-4">
                            <p className="">{page}</p>
                          </div>
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleNxtClick}
                              disabled={data.length < itemsPerPage}
                            >
                              {"Next >>"}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="p-2 text-start iq-card-body">
                        <Table>
                          <thead className="p-1">
                            <tr className="title">
                              <th>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                  />
                                </label>
                              </th>
                              <th>
                                <label>EPISODE</label>
                              </th>
                              <th>
                                <label>EPISODE NAME</label>
                              </th>
                              <th>
                                <label>SLIDER</label>
                              </th>
                              <th>
                                <label>STATUS</label>
                              </th>
                              <th>
                                <label>ACTION</label>
                              </th>
                            </tr>
                          </thead>
                        </Table>

                        <div className="text-center">
                          <label>No Episodes Available.</label>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Create an ad group",
      description: (
        <div className="iq-card-body ">
          <section className="">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>{label_management?.series_season_episode[0]?.title}</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={Manageepisode} className="btn btn-primary">
                    {label_management?.series_season_episode[43]?.title}
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>{label_management?.series_season_episode[1]?.title}</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      {label_management?.series_season_episode[2]?.title}<span className="mandatory">*</span>
                    </label>
                    <p className="t-1">
                      {label_management?.series_season_episode[2]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.title}
                        onKeyDown={handleInputKeyDowntitle}
                        placeholder="Title"
                        onBlur={handleBlurtitle}
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">{label_management?.series_season_episode[3]?.title}</label>
                    <p className="t-1">
                      {label_management?.series_season_episode[3]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg"
                        value={autoFillValuetitle}
                        onChange={handleAutoFillChangetitle}
                        placeholder="Episode Slug"
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">{label_management?.series_season_episode[4]?.title}</label>
                    <p className="t-1">
                      {label_management?.series_season_episode[4]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <textarea
                        className="rs-input short-description"
                        id="description"
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.series_season_episode[5]?.title}</label>
                      <p className="t-1">
                        {label_management?.series_season_episode[5]?.sub_title}
                      </p>
                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input"
                          value={description}
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.series_season_episode[6]?.title}</label>
                      <p className="t-1">
                        {label_management?.series_season_episode[6]?.sub_title}
                      </p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          maxLength="8"
                          className="rs-input form-control-lg"
                          onChange={handleInputdata}
                          value={editUser?.duration}
                          placeholder="duration"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.series_season_episode[7]?.title}</label>
                      <p className="t-1">
                        {label_management?.series_season_episode[7]?.sub_title}
                      </p>
                      <div className="mt-2">
                        <input
                          type="date"
                          name="date"
                          id="date"
                          value={editUser?.year || ''}
                          onChange={handleChangeyear}
                          className="rs-input form-control-lg"
                          placeholder="Year"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>{label_management?.series_season_episode[8]?.title}</h5>
                    <hr></hr>

                    <div className=" text-start">
                      <label className="m-0">{label_management?.series_season_episode[9]?.title}</label>
                      <p className="t-1">
                        {label_management?.series_season_episode[9]?.sub_title}
                      </p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={(e) => setRating(e.target.value)}
                        >
                          {/* <option value="0">0</option> */}
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>

                    <div className=" mt-2 text-start">
                      <label className="m-0">{label_management?.series_season_episode[10]?.title}</label>
                      <p className="t-1">
                        {label_management?.series_season_episode[10]?.sub_title}
                      </p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="age_restrict"
                          name="age_restrict"
                          size="lg"
                          onChange={(e) => setAge_restrict(e.target.value)}
                        >
                          <option value="0">Choose an Age</option>
                          {age_restrictdata?.map((item) => (
                            <option selected value={item?.id}>
                              {item?.slug}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.series_season_episode[11]?.title}</label>
                      <p className="t-1">
                        {label_management?.series_season_episode[11]?.sub_title}
                      </p>
                      <div className="mt-2">
                        <Select
                          options={artists?.map((option) => ({
                            value: option?.id,
                            label: option?.artist_name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionsartists}
                          onChange={handleSelectChangeartists}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.series_season_episode[12]?.title}<span className="mandatory">*</span></label>
                      <p className="t-1">
                        {label_management?.series_season_episode[12]?.sub_title}
                      </p>

                      <div className="mt-2">
                        <Select
                          options={category?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionscategory}
                          onChange={handleSelectChangecategory}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencategory && (
                          <p>{validationMessagecategory}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.series_season_episode[13]?.title}</label>
                      <p className="t-1">
                        {label_management?.series_season_episode[13]?.sub_title}
                      </p>
                      <div className="text-dark">
                        <Select
                          options={language?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionslanguage}
                          onChange={handleSelectChangelanguage}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.series_season_episode[14]?.title}</label>
                      <p className="t-1">
                        {label_management?.series_season_episode[14]?.sub_title}
                      </p>
                      <div className="text-dark">
                        <Select
                          options={block_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptions}
                          onChange={handleSelectChange}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.series_season_episode[15]?.title}</label>
                      <p className="t-1">
                        {label_management?.series_season_episode[15]?.sub_title}
                      </p>
                      <div className="text-dark">
                        <Select
                          options={available_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionsAvailable_country}
                          onChange={handleSelectChangeAvailable_country}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.series_season_episode[16]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">{label_management?.series_season_episode[17]?.title}</label>
                    <p className="t-1">
                      {label_management?.series_season_episode[17]?.sub_title}
                    </p>

                    <div className="mt-2">
                      <TagsInput
                        value={searchTags}
                        className="rs-input"
                        onChange={handleTagsChange}
                        name="search_tags"
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.series_season_episode[18]?.title}</h5>
                  <hr />

                  <div className="col-sm-12">
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.series_season_episode[19]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="free_duration_status"
                            id="free_duration_status"
                            onChange={handleInputenabletime}
                            defaultChecked={editUser?.free_duration_status === 1}
                            checked={editUser?.free_duration_status === 1}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            id="free_duration_status"
                            name="free_duration_status"
                            value={editUser?.free_duration_status === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0">{label_management?.series_season_episode[20]?.title}</label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="free_duration_time"
                            id="free_duration_time"
                            className="rs-input form-control-lg"
                            value={editUser?.free_duration_time}
                            maxLength="8"
                            placeholder="HH:MM:SS"
                            onChange={handleInputdatatime}
                            ref={free_duration_timeInputRef}
                          />
                          {!isValidationHiddenfree_duration_time && (
                            <div className="mandatory">
                              {validationMessagefree_duration_time}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Advertiment</h5>
                  <hr />

                  <div className="col-sm-12">
                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0">Ads Category</label>
                        <div className="mt-2">
                          <select
                            onChange={handleInput}
                            value={editUser?.ads_category}
                            name="ads_category"
                            id="ads_category"
                            className="form-select"
                          >
                            <option value="">Select a Ads Category</option>
                            {ads_category?.map((item, key) => (
                              <option key={key} value={item?.id}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0">Time Mid Advertisement</label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="mid_advertisement_sequence_time"
                            id="mid_advertisement_sequence_time"
                            className="rs-input form-control-lg"
                            value={editUser?.mid_advertisement_sequence_time}
                            maxLength="8"
                            placeholder="HH:MM:SS"
                            onChange={handleInputdatatime}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.series_season_episode[21]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12 row">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">{label_management?.series_season_episode[22]?.title}</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="featured"
                          onChange={handleInput}
                          className="rs-input"
                          defaultChecked={
                            featured?.featured == 1 ? true : false
                          }
                          checked={featured?.featured == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="featured"
                          onChange={handleInput}
                          value={featured?.featured == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 row mt-2">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">{label_management?.series_season_episode[23]?.title}</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="active"
                          onChange={handleInput}
                          defaultChecked={active?.active == 1 ? true : false}
                          checked={active?.active == 1 ? true : false}
                          className="rs-input"
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="active"
                          onChange={handleInput}
                          value={active?.active == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 row mt-2">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">{label_management?.series_season_episode[24]?.title}</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="banner"
                          onChange={handleInput}
                          className="r-input"
                          defaultChecked={banner?.banner == 1 ? true : false}
                          checked={banner?.banner == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="banner"
                          onChange={handleInput}
                          value={banner?.banner == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-sm-12 row mt-2">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Is this episode display in Footer</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="footer"
                          id="footer"
                          onChange={handleInput}
                          className="r-input"
                          defaultChecked={footer.footer == 1 ? true : false}
                          checked={footer.footer == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="footer"
                          id="footer"
                          onChange={handleInput}
                          value={footer.footer == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div> */}
                </div>

                <div className="text-start mb-3">
                  <button onClick={Manageepisode} className="btn btn-primary">
                    {label_management?.series_season_episode[43]?.title}
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>{label_management?.series_season_episode[25]?.title}</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.series_season_episode[26]?.title}
                      {compressResolutionFormat?.episode === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.series_season_episode[26]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1,
                                  'file1'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(1)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file1 && <div className="errorred mt-1">{error?.file1}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.series_season_episode[27]?.title}
                      {compressResolutionFormat?.episode === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.series_season_episode[27]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2,
                                  'file2'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(2)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file2 && <div className="errorred mt-1">{error?.file2}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">
                      {label_management?.series_season_episode[28]?.title}
                      {compressResolutionFormat?.episode === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">

                      {label_management?.series_season_episode[28]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3,
                                  'file3'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(3)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file3 && <div className="errorred mt-1">{error?.file3}</div>}
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>
                      {label_management?.series_season_episode[29]?.title} <small>{label_management?.series_season_episode[29]?.sub_title}</small>
                    </h5>
                    <hr></hr>

                    <div className="col-lg-12 row">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.series_season_episode[30]?.title} <small>{label_management?.series_season_episode[30]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              value={userTime?.skip_start_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              maxLength="8"
                              className="rs-input form-control-lg "
                              name="skip_start_time"
                              id="skip_start_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_start_time}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.series_season_episode[31]?.title} <small>{label_management?.series_season_episode[31]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              value={userTime?.recap_start_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              maxLength="8"
                              className="rs-input form-control-lg "
                              name="recap_start_time"
                              id="recap_start_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_start_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        {" "}
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.series_season_episode[32]?.title} <small>{label_management?.series_season_episode[32]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              value={userTime?.skip_end_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              maxLength="8"
                              className="rs-input form-control-lg "
                              name="skip_end_time"
                              id="skip_end_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_end_time}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.series_season_episode[33]?.title} <small>{label_management?.series_season_episode[33]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={userTime?.recap_end_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="recap_end_time"
                              id="recap_end_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_end_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.series_season_episode[34]?.title} <small>{label_management?.series_season_episode[34]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={userTime?.skip_start_session}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="skip_start_session"
                              id="skip_start_session"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_start_session}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.series_season_episode[35]?.title} <small>{label_management?.series_season_episode[35]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={userTime?.recap_start_session}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="recap_start_session"
                              id="recap_start_session"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_start_session}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>Intro Time</h5>
                    <hr></hr>
                    <div className="text-start">
                      <label className="m-0">
                        Skip Intro Time <small>(Please Give In Seconds)</small>
                      </label>

                      <div className="panel-body">
                        <input
                          className="rs-input form-control-lg "
                          name="skip_intro"
                          id="skip_intro"
                          onChange={(e) => setSkip_intro(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0">
                        Intro Start Time <small>(Please Give In Seconds)</small>
                      </label>

                      <div className="panel-body">
                        <input
                          className="rs-input form-control-lg "
                          name="intro_start_time"
                          id="intro_start_time"
                          onChange={(e) => setIntro_start_time(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0">
                        Intro End Time <small>(Please Give In Seconds)</small>
                      </label>

                      <div className="panel-body">
                        <input
                          className="rs-input form-control-lg "
                          name="intro_end_time"
                          id="intro_end_time"
                          onChange={(e) => setIntro_end_time(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0">
                        Skip Recap Time <small>(Please Give In Seconds)</small>
                      </label>

                      <div className="panel-body">
                        <input
                          className="rs-input form-control-lg "
                          name="skip_recap"
                          id="skip_recap"
                          onChange={(e) => setSkip_recap(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0">
                        Recap Start Time <small>(Please Give In Seconds)</small>
                      </label>

                      <div className="panel-body">
                        <input
                          className="rs-input form-control-lg "
                          name="recap_start_time"
                          id="recap_start_time"
                          onChange={(e) => setRecap_start_time(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0">
                        Recap End Time <small>(Please Give In Seconds)</small>
                      </label>

                      <div className="panel-body">
                        <input
                          className="rs-input form-control-lg "
                          name="recap_end_time"
                          id="recap_end_time"
                          onChange={(e) => setRecap_end_time(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12"> */}
                <div className="card p-3">
                  <h5>{label_management?.series_season_episode[36]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">{label_management?.series_season_episode[37]?.title}<span className="mandatory">*</span></label>
                    <p className="p1">
                      {label_management?.series_season_episode[37]?.sub_title}
                    </p>
                    <select
                      onChange={(e) => setStoreliveuseraccess(e.target.value)}
                      className="form-select"
                      id="access"
                      name="access"
                      onClick={(event) => {
                        setallliveuseraccess(event.target.value);
                      }}
                      ref={accessInputRef}
                    >
                      <option value="">Select User Access</option>
                      {/* {user_access?.map((item, key) => (
                        <option value={item?.role}>{item?.name}</option>
                      ))} */}
                      {filteredOptions?.map((item) => (
                        <option key={item?.value} value={item?.role || item?.value}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>{alllive_streamuseraccess()}</div>

                  <span className="errorred mt-2 d-block">
                    {!isValidationHiddenAccess && (
                      <p>{validationMessageAccess}</p>
                    )}
                  </span>

                </div>

                {/* <div className="card p-3">
                    <h5>Choose Ads Settings</h5>
                    <hr></hr>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Ads Position</label>

                      <div className=" mt-2">
                        <Select
                          options={adsPositions}
                          className="rs-input"
                          onChange={(e) => handleAdsPositionChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Advertisement</label>

                      <div className=" mt-2">
                        <Select
                          options={secondSelectOptions}
                          className="rs-input"
                          onChange={(e) => setSecondSelectOptions(e)}
                        />
                      </div>
                    </div>
                  </div> */}
                {/* </div> */}

                <div className="card p-3">
                  <h5>{label_management?.series_season_episode[38]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">{label_management?.series_season_episode[39]?.title}</label>
                    <p className="t-1">
                      {label_management?.series_season_episode[39]?.sub_title}
                    </p>
                    <div className=" mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        onChange={(e) => setWebsitetitle(e.target.value)}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 mt-2">
                    <label className="m-0">{label_management?.series_season_episode[40]?.title}</label>
                    <p className="t-1">
                      {label_management?.series_season_episode[40]?.sub_title}
                    </p>
                    <div className=" mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        onChange={(e) => setWebsiteurl(e.target.value)}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-2">
                    <label className="m-0">{label_management?.series_season_episode[41]?.title}</label>
                    <p className="t-1">
                      {label_management?.series_season_episode[41]?.sub_title}
                    </p>
                    <div className=" mt-2">
                      <textarea
                        type="text"
                        className="rs-input form-control-lg"
                        onChange={(e) => setMetadescription(e.target.value)}
                        placeholder=""
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.series_season_episode[42]?.title} <small>{label_management?.series_season_episode[42]?.sub_title}</small></h5>
                  <hr></hr>
                  <div className="mt-0">
                    {Subtitlesmap?.map((item, index) => (
                      <div className="col-sm-12 mt-2" key={index}>
                        <label>{item?.language}</label>
                        <div className="file-draganddrop mt-2">
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleDropsubtitles(
                                item?.short_code,
                                acceptedFiles
                              )
                            }
                            accept="video/*"
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()} className="dropzone">
                                <input {...getInputProps()} />
                                <p className="p-3">
                                  Drag and drop subtitles here
                                </p>
                                {videosubtitles[item?.short_code]?.length >
                                  0 && (
                                    <p>
                                      {videosubtitles[item?.short_code].length}{" "}
                                      {videosubtitles[item?.short_code]
                                        .length === 1
                                        ? "file"
                                        : "files"}{" "}
                                      selected
                                    </p>
                                  )}
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <div id="episode_video_data" className="episode_video_data">
            <div>
              <div className="row mt-4">
                <div className="col-md-6 ">
                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-heading">
                      <div className="panel-title">
                        <label>Title</label>
                      </div>
                    </div>
                    <div className="panel-body ">
                      <p className="p1">
                        Add the episodes title in the textbox below:
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        id="title"
                        placeholder="Episode Title"
                        value={editUser?.title}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-heading">
                      <div className="panel-title">
                        <label>Slug</label>
                      </div>
                    </div>
                    <div className="panel-body ">
                      <p className="p1">
                        Add the episodes slug in the textbox below:
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        id="title"
                        placeholder="Episode Slug"
                        onChange={(e) => setSlug(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-sm-6">
                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-heading">
                      <div className="panel-title">
                        <label>Episode Image Cover</label>
                      </div>
                    </div>
                    <div className="panel-body">
                      <p className="p1">
                        Select the episodes image (1080 X 1920px or 9:16 ratio):
                      </p>
                      <input
                        type="file"
                        multiple="true"
                        className="form-control"
                        name="image"
                        id="image"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <label className="m-0">Episode Player Image</label>
                  <p className="p1">
                    Select the player image ( 1280 X 720px or 16:9 Ratio )
                  </p>

                  <div className="panel-body">
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="player_image"
                      onChange={(e) => setPlayer_image(e.target.files[0])}
                      id="player_image"
                    />
                  </div>
                </div>

                <div className="col-sm-6 mt-3">
                  <label className="m-0">Episode TV Image</label>
                  <p className="p1">
                    Select the player image ( 16:9 Ratio or 1920 X 1080 px)
                  </p>

                  <div className="panel-body">
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="tv_image"
                      id="tv_image"
                      onChange={(e) => setTv_image(e.target.files[0])}
                    />
                  </div>
                </div>

                <div className="row mt-4 mb-3">
                  <div className="col-sm-12">
                    <label className="m-0"> Episode Description </label>
                    <p className="p1">
                      {" "}
                      Add a description of the Episode below:{" "}
                    </p>
                    <div className="panel-body">
                      
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-sm-6">
                    <div className="panel panel-primary" data-collapsed="0">
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label>Episode Ratings</label>
                        </div>
                      </div>
                      <div className="panel-body p-0">
                        <p className="p1">IMDb Ratings 10 out of 10</p>
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <img alt="dfghj" />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="panel-body col-sm-6">
                    <label>
                      <h6>Age Restrict :</h6>
                    </label>
                    <div className="panel-body">
                      <select
                        className="form-control"
                        id="age_restrict"
                        name="age_restrict"
                        onChange={(e) => setAge_restrict(e.target.value)}
                      >
                        <option selected value="">
                          Choose an Age
                        </option>
                        {age_restrictdata?.map((item) => (
                          <option selected value={item?.id}>
                            {item?.slug}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 p-0">
                    <label>Search Tags :</label>
                    <div className="panel-body">
                      <input
                        className="form-control"
                        type="text"
                        id="tag-input1"
                        name="searchtags"
                      />
                    </div>
                  </div>

                  
                </div>

                <div className="row mt-3">
                  <div className="panel-body col-sm-6">
                    <label>
                      <h6>Ads Position:</h6>
                    </label>
                    <input
                      name="ads_position"
                      className="form-control"
                      onChange={(e) => setAds_position(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6 p-0">
                    <label>Episode Ads :</label>
                    <div className="panel-body">
                      <input
                        className="form-control"
                        type="text"
                        id="episode_ads"
                        name="episode_ads"
                        onChange={(e) => setEpisode_ads(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row align-items-center mt-4">
                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Skip Intro Time <small>(Please Give In Seconds)</small>
                      </label>

                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="skip_intro"
                          id="skip_intro"
                          onChange={(e) => setSkip_intro(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Intro Start Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="intro_start_time"
                          id="intro_start_time"
                          onChange={(e) => setIntro_start_time(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Intro End Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="intro_end_time"
                          id="intro_end_time"
                          onChange={(e) => setIntro_end_time(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row align-items-center mt-4">
                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Skip Recap Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="skip_recap"
                          id="skip_recap"
                          onChange={(e) => setSkip_recap(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 ">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Recap Start Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="recap_start_time"
                          id="recap_start_time"
                          onChange={(e) => setRecap_start_time(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Recap End Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="recap_end_time"
                          id="recap_end_time"
                          onChange={(e) => setRecap_end_time(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row text-start mt-4">
                  <div className="col-sm-6">
                    <label className="m-0">Choose Ads Position</label>

                    <div className="">
                      <Select
                        options={adsPositions}
                        onChange={(e) => handleAdsPositionChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label className="m-0">Choose Advertisement</label>

                    <div className="">
                      <Select
                        options={secondSelectOptions}
                        onChange={(e) => setSecondSelectOptions(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row align-items-center mt-4">
                  <div className="col-sm-6">
                    <div className="panel panel-primary" data-collapsed="0">
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label>Duration</label>
                        </div>
                      </div>
                      <div className="panel-body">
                        <p className="p1">
                          Enter the episode duration in the following format
                          (Hours : Minutes : Seconds)
                        </p>
                        <input
                          type="text"
                          className="form-control"
                          name="duration"
                          id="duration"
                          value={editUser?.duration}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label className="m-0">User Access</label>
                    <p className="p1">
                      Who Is Allowed To View This episode ?
                    </p>
                    <select
                      className="form-control"
                      onClick={(event) => {
                        setLivesource(event.target.value);
                      }}
                    >
                     
                    </select>
                  </div>
                </div>

                <div>{live_stream_source()}</div>

                <div className="row align-items-center mt-4">
                  <div className="col-sm-6">
                    <div className="panel panel-primary" data-collapsed="0">
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label className="fs-5">Status Settings</label>
                        </div>
                      </div>

                      <div className="panel-body">
                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="featured">
                              Is this episode Featured:
                            </label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="featured"
                                onChange={handleInput}
                                defaultChecked={
                                  featured?.featured === 1 ? true : false
                                }
                                checked={
                                  featured?.featured === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="featured"
                                onChange={handleInput}
                                value={featured?.featured === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="featured">
                              Is this episode Status:
                            </label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="status"
                                onChange={handleInput}
                                defaultChecked={
                                  status?.status === 1 ? true : false
                                }
                                checked={status?.status === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                onChange={handleInput}
                                value={status?.status === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="featured">Is this episode Views:</label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="views"
                                onChange={handleInput}
                                defaultChecked={
                                  views?.views === 1 ? true : false
                                }
                                checked={views?.views === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="views"
                                onChange={handleInput}
                                value={views?.views === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="active">Is this episode Active:</label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="active"
                                onChange={handleInput}
                                defaultChecked={
                                  active?.active === 1 ? true : false
                                }
                                checked={active?.active === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="active"
                                onChange={handleInput}
                                value={active?.active === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="banner">
                              Is this episode display in Banner:
                            </label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="banner"
                                onChange={handleInput}
                                defaultChecked={
                                  banner?.banner === 1 ? true : false
                                }
                                checked={banner?.banner === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="banner"
                                onChange={handleInput}
                                value={banner?.banner === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="footer">
                              Is this episode display in Footer:
                            </label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="footer"
                                onChange={handleInput}
                                defaultChecked={
                                  footer?.footer === 1 ? true : false
                                }
                                checked={footer?.footer === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="footer"
                                onChange={handleInput}
                                value={footer?.footer === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <input
                    type="submit"
                    id="submit"
                    value="Create Episode"
                    className="btn btn-primary pull-right"
                    onClick={Manageepisode}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      ),
    },
  ];

  const fetchMenuItems = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/episode/order`,
        { headers: headers }
      );
      setMenuItems(response.data.Web_HomePage_Order);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(async () => {
    fetchMenuItems();
  }, []);

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid">
          <div className="">
            <div className="row">
              <div className="col-sm-12">
                <div className="addepisode">
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps?.map((step, index) => (
                      <Step key={step?.label}>
                        <StepContent>
                          <Typography>{step?.description}</Typography>
                          <div>
                            <div className="text-end mt-3">
                              {/* <button onClick={handleNextNext} className='btn btn-primary' hidden={index ==== 1}>
                                Proceed to Next
                              </button> */}
                              {/* <button
                                hidden={index ==== 0} className='btn btn-primary'
                                onClick={handleBack}
                              >
                                Back
                              </button> */}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
